import React from "react";
//PRIMEREACT
//COMPONENTES
//ESTILOS
import "./Albumes.css";
//RUTAS
import { Link } from "react-router-dom";
//CONTEXT

function Albumes({ albumesData }) {
  return (
    <>
      <div className="grid">
        <div className="col-12 contentForm">
          <div className="grid">
            <div className="col-12 md:col-10 md:col-offset-1">
              <h2 className="mt-0 text-center">
                <b>Álbumes de fotos</b>
              </h2>
            </div>

            <div className="col-12">
              <div className="grid">
                {albumesData
                  ? albumesData.map((al) => {
                      return (
                        <div className="col-12 md:col-3" key={al.id}>
                          <Link to={`/portal/${al.id}`} className="linkAlbum">
                            <img src={al.imgPortada} alt={al.desc} />

                            <h2 className="titleAlbum">{al.name}</h2>
                          </Link>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Albumes;
