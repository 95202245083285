import React, { useState } from "react";
import { useCart } from "../../context/cart-context";
import Messages from "../Others/Messages";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useUsuario } from "../../context/usuario-context";
import LoadSkeletonProducts from "../../components/LoadSkeletonProducts.js";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import fInventory from "../../services/fInventory";
import { Message } from "primereact/message";
import { useNavigate } from "react-router-dom";

import "./index.css";

const ShoppingCart = () => {
  const { dataUser, configurations, resetDataUser } = useUsuario();
  const {
    cart,
    removeFromCart,
    setQuantity,
    getTotalItems,
    getTotalCost,
    inventory,
    loadingInventory,
    resetCart,
  } = useCart();

  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //CONTROL DE MENSAJES <--

  const accept = () => {
    setLoading(true);
    let data = [];
    data.addressSend = dataUser.dataBranch.address;
    data.nameBranch = dataUser.dataBranch.nameBranch;
    data.mobilePhone = dataUser.mobilePhone;
    data.sendType = 3;
    data.to = configurations.eCommunications;
    data.to.push(dataUser.email);
    fInventory
      .addOrder(data, cart, dataUser, getTotalCost())
      .then((result) => {
        setHeaderMessage(result.headerMessage);
        setIcon(result.icon);
        setColorIcon(result.colorIcon);
        setMessage(result.message);
        setShowMessage(true);
        resetCart();
        setLoading(false);
      })
      .catch((reason) => {
        setHeaderMessage(reason.headerMessage);
        setIcon(reason.icon);
        setColorIcon(reason.colorIcon);
        setMessage(reason.message);
        setShowMessage(true);
        resetCart();
        console.log(reason);
        setLoading(false);
      });
  };

  const reject = () => {
    console.log("rechazado");
  };

  const confirm1 = (event) => {
    confirmDialog({
      message: textSend,
      header: "Confirmación",
      accept,
      reject,
    });
  };

  const accept2 = (id) => {
    removeFromCart(id);
  };
  const reject2 = () => {
    console.log("rechazado");
  };

  const confirm2 = (event) => {
    confirmDialog({
      header: "Confirmación",
      target: event.currentTarget,
      message:
        "¿Estás seguro de que deseas eliminar este producto de tu carrito de compras?",
      icon: "pi pi-info-danger",
      accept: () => accept2(event),
      reject: () => reject2(),
    });
  };

  const hideDialogSend = () => {
    setShowMessage(false);
    setLoading(false);
    resetDataUser();
  };

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  const showButton = fInventory.shouldShowButton();

  const textSend = (
    <div className="uppercase">
      <p className="nameItem text-left m-0 visiblemd">
        <b>
          El pedido será enviado <br /> a la siguiente dirección:
        </b>
      </p>
      <br />
      <p className="nameItem text-left m-0 visiblemd">
        <b>Sucursal:</b>{" "}
        {dataUser && dataUser.dataBranch
          ? dataUser.dataBranch.nameBranch
          : "---"}
      </p>
      <p className="nameItem text-left m-0 visiblemd">
        <b>Dirección:</b>{" "}
        {dataUser && dataUser.dataBranch ? dataUser.dataBranch.address : "---"}
      </p>
      <p className="nameItem text-left m-0 visiblemd">
        <b>Nombre del responsable:</b>{" "}
        {dataUser && dataUser.dataBranch ? dataUser.fullName : "---"}
      </p>
      <p className="nameItem text-left m-0 visiblemd">
        <b>Número de teléfono:</b>{" "}
        {dataUser && dataUser.dataBranch ? dataUser.mobilePhone : "---"}
      </p>
    </div>
  );

  if (loadingInventory) {
    return <LoadSkeletonProducts name="Cargando..." />;
  }

  if (!showButton) {
    return (
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <h1>Carrito de compras</h1>
          <div className="grid contentForm flex align-items-center justify-content-between flex-row ">
            <span>Vuelve del 18 al 28 de cada mes para redimir tus puntos</span>
            {/* <span>Vuelve del 28 al 7 de cada mes para redimir tus puntos</span> */}
            <div>
              <Button
                icon="pi pi-arrow-left"
                onClick={back}
                label="Volver"
                className="p-button p-button-brilla"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (cart.length === 0) {
    return (
      <>
        <div className="grid grid-nogutter container-principal">
          <div className="col-12 md:col-10 md:col-offset-1">
            <h1>Carrito de compras</h1>
            <div className="grid contentForm flex align-items-center justify-content-between flex-row ">
              <span>NO HAY PRODUCTOS</span>
              <div>
                <Button
                  icon="pi pi-arrow-left"
                  onClick={back}
                  label="Volver"
                  className="p-button p-button-brilla"
                />
              </div>
            </div>
          </div>
        </div>
        <Messages
          showMessage={showMessage}
          actionHidden={hideDialogSend}
          headerMessage={headerMessage}
          message={message}
          icon={icon}
          color={colorIcon}
        />
      </>
    );
  }

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <h1>Carrito de compras</h1>
          <div className="grid contentForm">
            <div className="col-8">
              <div className="grid">
                {cart.map((item) => {
                  const inventoryItem = inventory.find(
                    (invItem) => invItem.id === item.id
                  ); // buscar el item en inventory

                  return (
                    <div className="grid pb-4 pt-6 borderB" key={item.id}>
                      <div className="col-12 md:col-3 img-responsive visiblemd">
                        <img src={item.img} alt={item.name} />
                      </div>
                      <div className="col-12 md:col-9 visiblemd">
                        <div className="grid pl-3">
                          <div className="col-12 pb-0">
                            <p className="nameOrder m-0 mb-3">{item.name}</p>
                          </div>
                          <div className="col-12 pt-0">
                            <p className="orderDesc m-0">{item.description}</p>
                          </div>
                          <div className="col-12">
                            <p className="olimposOrder m-0">
                              {item.cost} Olimpos
                            </p>
                          </div>
                          <div className="col-12">
                            <InputNumber
                              value={item.quantity}
                              onValueChange={(e) => {
                                setQuantity(item.id, e.value);
                              }}
                              mode="decimal"
                              showButtons
                              min={1}
                              inputStyle={{ width: "40px" }}
                              max={inventoryItem ? inventoryItem.quantity : 0} // establecer max al quantity del item en inventory
                            />
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger p-button-outlined ml-4"
                              aria-label="Search"
                              onClick={() => {
                                confirm2(item.id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-4 px-4">
              {dataUser.olimpos < getTotalCost() && getTotalCost() > 0 ? (
                <Message
                  severity="error"
                  text="No tienes puntos suficientes"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
              ) : null}

              <h2>Resumen de la orden</h2>
              <div className="grid">
                <div className="col-8 flex align-items-center textPoint">
                  Tu saldo en Olimpos es:
                </div>
                <div className="col-4 text-right numberPoint">
                  {dataUser.olimpos}
                </div>
                <div className="col-8 flex align-items-center textPoint">
                  Total de productos:
                </div>
                <div className="col-4 text-right numberPoint">
                  {getTotalItems()}
                </div>
                <div className="col-8 flex align-items-center textPoint">
                  Total de Olimpos a redimir:
                </div>
                <div className="col-4 text-right numberPoint">
                  {getTotalCost()}
                </div>
              </div>
              <Button
                label="Redimir"
                className="mt-4 p-button p-component p-button-brilla"
                onClick={confirm1}
                disabled={
                  dataUser.olimpos < getTotalCost() && getTotalCost() > 0
                }
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog />
    </>
  );
};

export default ShoppingCart;
