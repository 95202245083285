import React, { useState, useEffect, useRef } from "react";
//PRIMEREACT
import { Galleria } from "primereact/galleria";
//COMPONENTES
import Loader from "../LoaderSimple";
//ESTILOS
import "./Albumes.css";
//ROUTER
import { useParams } from "react-router-dom";
//SERVICIOS
import fglobales from "../../services/fglobales";
//CONTEXT
import { useUsuario } from "../../context/usuario-context";

function GaleriaItems() {
  const { dataUser, configurations } = useUsuario();
  const { id } = useParams();
  const galleria = useRef(null);
  const [galeryData, setGaleryData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataGalery = await fglobales.getDatosABC(
          "galery",
          "albumData.id",
          id
        );

        setGaleryData(dataGalery);
      } catch (error) {
        // Manejar errores de manera adecuada
        console.error("Error al obtener datos de usuarios ganadores:", error);
      }
    };

    fetchData();
  }, [dataUser, configurations, id]);

  const itemTemplate = (item) => {
    return (
      <img
        src={item.imgUrl}
        alt={item.name}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.imgThumbnail}
        alt={item.name}
        style={{ display: "block" }}
      />
    );
  };

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          {galeryData && galeryData.length > 0 ? (
            <h1 className="mt-0"> {galeryData[0].albumData.desc}</h1>
          ) : (
            <Loader />
          )}

          <div className="grid">
            <div className="col-12 contentForm">
              {galeryData ? (
                <>
                  <Galleria
                    ref={galleria}
                    value={galeryData}
                    numVisible={galeryData.length}
                    style={{ maxWidth: "900px" }}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    circular
                    fullScreen
                    showItemNavigators
                    showThumbnails={false}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                  />

                  <div className="grid">
                    {galeryData &&
                      galeryData.map((image, index) => {
                        let imgEl = (
                          <img
                            src={image.imgThumbnail}
                            onError={(e) =>
                              (e.target.src =
                                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                            }
                            alt={image.name}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setActiveIndex(index);
                              galleria.current.show();
                            }}
                          />
                        );

                        return (
                          <div className="col-6 md:col-3" key={index}>
                            {imgEl}
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GaleriaItems;
