import React from "react";
//PRIMEREACT
import { Image } from "primereact/image";
import { Button } from "primereact/button";
//COMPONENTES
import Banner from "../Banner";
//ESTILOS
import "./Titanes.css";
//MEDIA
import Medalla from "../../assets/images/medallas.png";
import Titan from "../../assets/images/titan.png";
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//RUTAS
import { Link } from "react-router-dom";

function Titanes() {
  const { dataUser } = useUsuario();
  return (
    <>
      <Banner bannerName={"titanes"} />
      <div className="grid grid-nogutter container-principal container-titan">
        <div className="col-12 md:col-10 md:col-offset-1">
          {!dataUser ? (
            <>
              <div className="grid visiblemd">
                <div className="col-12 md:col-6">
                  <Image src={Titan} alt="TitanBrilla" />
                </div>
                <div className="col-12 md:col-6 p-2">
                  <h1 className="sinM">
                    ¿Quieres ser parte de <br />
                    Titanes Brilla?
                  </h1>
                  <br />
                  <p className="text-6xl text-left">
                    <b>¡Bienvenidos a la tierra del Olimpo!</b>
                  </p>
                  <p>
                    Es tiempo de demostrar que son verdaderos Titanes Brilla. Su
                    valentía, inteligencia, perseverancia y sentido de
                    pertenencia los llevarán lejos en la misma tierra donde
                    existieron los gigantes que lo dominaban todo con cada una
                    de sus capacidades.
                  </p>
                </div>
              </div>

              <div className="grid visiblesm">
                <div className="col-12 md:col-6">
                  <h1 className="sinM">
                    ¿Quieres ser parte de
                    <br />
                    Titanes Brilla?
                  </h1>
                </div>
                <div className="col-12 md:col-6 p-2">
                  <Image src={Titan} alt="TitanBrilla" />
                  <p className="text-6xl text-left">
                    <b>¡Bienvenidos a la tierra del Olimpo!</b>
                  </p>
                  <p className="mt-0">
                    Es tiempo de demostrar que son verdaderos Titanes Brilla. Su
                    valentía, inteligencia, perseverancia y sentido de
                    pertenencia los llevarán lejos en la misma tierra donde
                    existieron los gigantes que lo dominaban todo con cada una
                    de sus capacidades.
                  </p>
                </div>
              </div>

              <div className="grid">
                <div className="col-12">
                  <h1>
                    Regístrate y vive la experiencia <br /> de ser uno de los
                    Titanes Brilla.
                  </h1>
                </div>

                <div className="col-12 md:col-4 md:col-offset-4">
                  <Link className="reistrateA" to="/registrate">
                    <Button className="p-button-vermas fullR" type="submit">
                      Regístrate aquí
                    </Button>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {dataUser ? (
            <>
              <div className="grid">
                <div className="col-12 md:col-6 p-2">
                  <p className="text-4xl md:text-6xl text-left">
                    <b>
                      ¡{dataUser.dataBranch.nameBranch} ahora ustedes son los
                      protagonistas!
                    </b>
                  </p>
                  <p className="mt-0">
                    Para ser parte de nuestro programa de insignias deberán
                    afrontar retos día a día que demuestren su pasión y
                    desempeño en esta tierra del Olimpo.
                  </p>
                </div>
                <div className="col-12 md:col-6 p-2">
                  <Image src={Medalla} alt="TitanBrilla" />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Titanes;
