import React, { useState } from "react";
import LoadSkeletonProducts from "../../../components/LoadSkeletonProducts.js";
import { useCart } from "../../../context/cart-context.js";

//PRIMEREACT
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import fInventory from "../../../services/fInventory.js";
//FIREBASE

function Product({ openModal }) {
  const {
    cart,
    addToCart,
    setQuantity,
    removeFromCart,
    inventory,
    loadingInventory,
  } = useCart();

  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(12);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  if (loadingInventory) {
    return <LoadSkeletonProducts name="Cargando..." />;
  }

  const showButton = fInventory.shouldShowButton();

  return (
    <>
      <div className="grid p-2 md:p-5">
        <div className="col-12">
          <div className="grid">
            {inventory.slice(basicFirst, basicFirst + basicRows).map((inv) => {
              const itemInCart = cart.find((item) => item.id === inv.id);

              return (
                <div
                  className="col-10 col-offset-1 md:col-3 md:col-offset-0 pl-2 pr-2 pb-4"
                  key={inv.id}
                >
                  <div className="grid contenedorItems">
                    <div className="col-11 centrar itemMar">
                      <img src={inv.img} alt="Item" />
                      <p className="nameItemPro">{inv.name}</p>
                      <p className="olimposItem">{inv.cost} Olimpos</p>
                      <p className="subtcampanas text-center">
                        <b>Cant:</b> {inv.quantity} disponibles
                      </p>

                      {showButton ? (
                        itemInCart ? (
                          <InputNumber
                            value={itemInCart.quantity}
                            onValueChange={(e) => {
                              if (e.value === 0) {
                                removeFromCart(itemInCart.id);
                              } else {
                                setQuantity(itemInCart.id, e.value);
                              }
                            }}
                            mode="decimal"
                            showButtons
                            min={0}
                            max={inv.quantity}
                            inputStyle={{ width: "40px" }}
                          />
                        ) : (
                          <Button
                            className="p-button p-component p-button-brilla"
                            type="submit"
                            onClick={() => {
                              addToCart(inv);
                              openModal(inv);
                            }}
                          >
                            Redimir
                          </Button>
                        )
                      ) : (
                        <span className="text-message-product">
                          Disponible del 18 al 28 de cada mes
                          {/* Disponible del 28 al 7 de cada mes */}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={inventory.length}
        rowsPerPageOptions={[4, 8, 16]}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </>
  );
}

export default Product;
