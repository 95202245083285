import React from "react";
import { Badge } from "primereact/badge";
import { useCart } from "../../../context/cart-context.js";
import { useNavigate } from "react-router-dom";
function CantOlimpos({ dataUser, openModalOrder }) {
  let navigate = useNavigate();
  const { getTotalItems } = useCart();

  return (
    <div className="grid">
      <div className="col-12 md:col-2 md:pr-5 ">
        <p
          className="orderHistorial mt-0"
          onClick={() => {
            openModalOrder();
          }}
        >
          <i className="pi pi-history"></i> Histórico
        </p>
      </div>
      <div className="col-12 md:col-8 md:pl-5 md:pr-2">
        <p className="olimpos mt-0 mb-2">
          Tienes: <b>{dataUser.olimpos} Olimpos</b> disponibles
        </p>
      </div>

      <div className="col-12 md:col-2 flex flex-wrap justify-content-center align-content-center">
        <i
          className="pi pi-shopping-cart p-overlay-badge cursor-pointer"
          style={{ fontSize: "2rem" }}
          onClick={() => {
            navigate(`/Carrito`);
          }}
        >
          <Badge value={getTotalItems()} className="orderBag"></Badge>
        </i>
      </div>
    </div>
  );
}

export default CantOlimpos;
