import React, { useState } from "react";
// PRIMEREACT
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
//MEDIA
//SERVICIOS
import fglobales from "../../services/fglobales";

export default function ModalComments({
  showMessage,
  actionHidden,
  showComments,
  dataUser,
}) {
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      comments: "",
    },
    validationSchema: Yup.object({
      subComments: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const addcoment = await fglobales.addSubComments(
          "campaign",
          showComments,
          formData,
          dataUser,
        );

        if (addcoment) {
          setSubmitted(false);
          actionHidden();
          formik.resetForm();
        }
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      // footer={dialogFooter}
      showHeader
      style={{ width: "50%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={<h3 className="m-0 text-center">Responder</h3>}
    >
      <div className="grid grid-nogutter">
        <div className="col-10 col-offset-1 p-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="col-12 pt-0">
              <InputTextarea
                placeholder="Escribe aquí tu comentario..."
                value={formik.values.subComments}
                id="subComments"
                name="subComments"
                onChange={formik.handleChange}
                rows={5}
                cols={20}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("subComments"),
                  },
                  "commentsText"
                )}
                disabled={submitted}
              />
              {getFormErrorMessage("subComments")}
            </div>

            <div className="col-12 md:col-4 md:col-offset-4">
              <Button
                className="p-button-brilla"
                type="submit"
                disabled={submitted}
              >
                Enviar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
}
