import React from "react";
//PRIMEREACT
import { Dropdown } from "primereact/dropdown";
//COMPONENTES
//ESTILOS
import "./NotiBrillaHistorical.css";
//FIREBASE

function History({ newsData, setSelectNoti, selectNoti }) {
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="campanasHis">
          <p className="tcampanas mb-1 mt-1">{option.desc}</p>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="campanasHis">
        <p className="tcampanas mb-1 mt-1">{option.desc}</p>
      </div>
    );
  };

  return (
    <>
      <Dropdown
        optionLabel="name"
        options={newsData}
        // id="campanas"
        // name="campanas"
        value={selectNoti}
        onChange={(e) => {
          setSelectNoti({ ...e.value });
        }}
        className="dropListC textWi"
        valueTemplate={selectedCountryTemplate}
        itemTemplate={countryOptionTemplate}
        placeholder="Seleccciona el Notibrilla"
      />
    </>
  );
}

export default History;
