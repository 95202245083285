import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
//MEDIA
import M5 from "../../assets/images/5_olimpos.png";
import M10 from "../../assets/images/10_olimpos.png";
import M15 from "../../assets/images/15_olimpos.png";
import moment from "moment";
import myformats from "../../services/myformats";

export default function ModalDataInsignias({
  showMessage,
  actionHidden,
  showInsignia,
}) {
  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      // footer={dialogFooter}
      showHeader
      style={{ width: "60%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={
        <>
          <h3 className="m-0 text-center">
            {showInsignia.monthAndYear
              ? myformats.cFL(
                  moment(showInsignia.monthAndYear.toDate()).format("MMMM")
                )
              : "----"}{" "}
            {showInsignia.monthAndYear
              ? myformats.cFL(
                  moment(showInsignia.monthAndYear.toDate()).format("YYYY")
                )
              : "----"}
          </h3>
        </>
      }
    >
      <div className="col-12 p-4">
        <div className="grid">
          <div className="col-12 md:col-10 md:col-offset-1">
            <div className="grid">
              <div className="col-12 md:col-4 text-center">
                <img
                  src={showInsignia.imgUrl}
                  alt="Insignias"
                  className="imgInsigniasModal"
                />

                <p className="nameIng">
                  <b>{showInsignia.name}</b>
                </p>
              </div>

              {showInsignia.type === 1 ? (
                <div className="col-12 md:col-8">
                  <div className="grid">
                    <div className="col-10">
                      <div className="grid">
                        <div className="col-12 p-0">
                          <p className="tMisiones">
                            {showInsignia.misiones
                              ? showInsignia.misiones[0]
                              : ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="textmisiones">5 Olimpos</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 pl-1 pr-1">
                      <img src={M5} alt="Olimpo" className="imgMe" />
                    </div>
                  </div>

                  <div className="grid">
                    <div className="col-10">
                      <div className="grid">
                        <div className="col-12 p-0">
                          <p className="tMisiones">
                            {showInsignia.misiones
                              ? showInsignia.misiones[1]
                              : ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="textmisiones">10 Olimpos</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 pl-1 pr-1">
                      <img src={M10} alt="Olimpo" className="imgMe" />
                    </div>
                  </div>

                  <div className="grid">
                    <div className="col-10">
                      <div className="grid">
                        <div className="col-12 p-0">
                          <p className="tMisiones">
                            {showInsignia.misiones
                              ? showInsignia.misiones[2]
                              : ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="textmisiones">15 Olimpos</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 pl-1 pr-1">
                      <img src={M15} alt="Olimpo" className="imgMe" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 md:col-8">
                  <p className="textmisiones">{showInsignia.desc}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
