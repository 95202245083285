import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// PRIMEREACT

export default function Messages(props) {
  const { showMessage, actionHidden, message, headerMessage, icon, color } =
    props;

  const dialogFooter = (
    <div className="flex flex-column align-items-center justify-content-center">
      <Button
        label="Cerrar"
        className="p-button-brilla"
        autoFocus
        onClick={() => actionHidden()}
      />
    </div>
  );

  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      footer={dialogFooter}
      showHeader={false}
      style={{ width: "40%", borderRadius: "30px" }}
      breakpoints={{ "960px": "80%" }}
    >
      <div className="flex flex-column align-items-center justify-content-center p-5">
        <i
          className={`pi ${icon}`}
          style={{ fontSize: "5rem", color: `var(--${color})` }}
        ></i>
        <h3 style={{ color: `var(--${color})`, textAlign: "center" }}>{headerMessage}</h3>
        <p className="modalP text-lecf">{message}</p>
      </div>
    </Dialog>
  );
}
