import React, { useState } from "react";
//COMPONENTES
import ModalAdviser from "../Register/ModalAdviser";
//PRIMEREACT
import { Button } from "primereact/button";
//SERVICIOS
import fanalytics from "../../services/fanalytics";

function AdviserProfile({ dataUser }) {
  const [showMessageAdviser,setShowMessageAdviser] = useState(false);

  const actionHiddenMessage = () => {
    setShowMessageAdviser(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessageAdviser(false);
  };

  const openModal = () => {
    setShowMessageAdviser(true);
  };

  return (
    <>
      <div className="col-12 md:col-10 md:col-offset-1 mt-4 contentForm">
        <div className="grid">
          <div className="col-12 md:col-10 md:col-offset-1">
            <h2 className="mt-0 text-center">
              <b>Asesores registrados</b>
            </h2>
          </div>

          <div className="col-12">
            <Button
              className="p-button-brilla"
              type="submit"
              onClick={() => {
                openModal();
                fanalytics.logEvents(
                  "button_click",
                  "button",
                  "ver_asesores"
                );
              }}
            >
              Ver asesores
            </Button>
          </div>
          {/*
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="sprite-animal ape"></div>
            </div>
          </div> */}
          {/*
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="sprite-animal sonic1"></div>
            </div>
          </div> */}
        </div>
      </div>

      <ModalAdviser
        showMessageAdviser={showMessageAdviser}
        actionHidden={actionHiddenMessage}
        dataUserPre={dataUser}
      />
    </>
  );
}

export default AdviserProfile;
