import React from "react";
//COMPONENTES
import Header from "./Header_2";
import Footer from "./Footer";

function Layout({ children, configurations }) {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer configurations={configurations} />
    </>
  );
}

export default Layout;
