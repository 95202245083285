import React, { useState, useEffect } from "react";
//COMPONENTES
import moment from "moment";
import ModalItems from "./ModalItems";
import ModalSend from "./ModalSend";
import Messages from "../Others/Messages";
import ModalOrder from "./ModalOrder";
//ESTILOS
import "./Marketplace.css";
//CONTEXT
import { useUsuario } from "../../context/usuario-context.jsx";
import Product from "./Template/Product";
import NumberOlimpos from "./Template/numberOlimpos";
//FIREBASE
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";

function Marketplace() {
  const { dataUser, configurations, resetDataUser } = useUsuario();
  const [showItem, setShowItem] = useState(false);
  const [showMessageSend, setShowMessageSend] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [item, setItem] = useState([]);
  const [orders, setOrders] = useState([]);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  useEffect(() => {
    const q = query(
      collection(db, "order"),
      where("creatorUser.dni", "==", dataUser.dni),
      orderBy("timestamp", "desc")
    );
    const unlistenI = onSnapshot(
      q,
      (docs) => {
        let orderHistory = [];

        docs.forEach((doc) => {
          let dateWinner = "";
          doc.data().timestamp
            ? (dateWinner = moment(doc.data().timestamp.toDate()).format(
                "DD/MM/YYYY"
              ))
            : (dateWinner = "---");

          orderHistory.push({ ...doc.data(), id: doc.id, dateBuy: dateWinner });
        });

        setOrders(orderHistory);
      },
      (error) => {
        console.log("Error fetching data", error);
      }
    );

    return () => {
      unlistenI();
    };
  }, [dataUser.dni]);

  const actionHiddenMessage = () => {
    setShowMessage(false);
    setShowMessageSend(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setShowMessageSend(false);
    setShowItem(false);
  };

  const hideDialogSend = () => {
    setShowMessageSend(false);
    resetDataUser();
  };

  const hideDialogOrder = () => {
    setShowOrder(false);
  };

  const openModal = (dataIns) => {
    setItem(dataIns);
    setShowItem(true);
  };

  const openModalSend = () => {
    setShowMessageSend(true);
  };

  const openModalOrder = () => {
    setShowOrder(true);
  };

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <h1 className="mt-0">
            Redime aquí tus <br />
            Olimpos por beneficios
          </h1>
          <br />
          <div className="grid">
            <div className="col-12 md:col-10 md:col-offset-1 mt-4 contentForm">
              <NumberOlimpos
                dataUser={dataUser}
                openModalOrder={openModalOrder}
              />
              {/* Producto */}
              <Product openModal={openModal} />
            </div>
          </div>
        </div>
      </div>

      <ModalItems
        showItem={showItem}
        actionHidden={actionHiddenMessage}
        item={item}
        openModalSend={openModalSend}
        dataUser={dataUser}
      />

      <ModalSend
        showMessageSend={showMessageSend}
        actionHidden={actionHiddenMessage}
        setHeaderMessage={setHeaderMessage}
        setColorIcon={setColorIcon}
        setIcon={setIcon}
        setMessage={setMessage}
        setShowMessage={setShowMessage}
        item={item}
        dataUser={dataUser}
        configurations={configurations}
      />

      <Messages
        showMessage={showMessage}
        actionHidden={hideDialogSend}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />

      <ModalOrder
        showOrder={showOrder}
        actionHidden={hideDialogOrder}
        orders={orders}
      />
    </>
  );
}

export default Marketplace;
