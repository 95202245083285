import React, { useState, useEffect } from "react";
//PRIMEREACT
import { Image } from "primereact/image";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
//ESTILOS
import "./Lineas.css";
//MEDIA
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Lineas() {
  const { dataUser } = useUsuario();
  const [dataAcellerator, setDataAcellerator] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataAccell = await fglobales.getDatosABC(
          "accelerators",
          "status",
          true
        );

        if (dataAccell.length > 0) {
          const userLineIds = new Set(dataUser.lines.map((line) => line.id));

          const matchingItems = dataAccell.filter((item) => {
            if (item.lines) {
              for (const line of item.lines) {
                if (userLineIds.has(line.id)) {
                  return true;
                }
              }
            }
            return false;
          });

          setDataAcellerator(matchingItems);
        } else {
          console.log("No se encontraron Datos.");
        }
      } catch (error) {
        // Manejar errores de manera adecuada
        console.error("Error al obtener datos de aceleradores:", error);
      }
    };

    fetchData();
  }, [dataUser]);

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <h1>Aceleradores</h1>
          <p className="text-center pb-5">
            ¡Titanes! En esta sección podrás acceder a los aceleradores que
            corresponden a tu línea activa.
            <br />
            Seguimos trabajando para mantener tu Brillo en su mayor esplendor y
            así posicionarte en el Olimpo como un gran Titán.
          </p>

          <div className="grid contentForm">
            {dataAcellerator.length > 0 ? (
              dataAcellerator.map((ac, i) => {
                return (
                  <div className="col-12" key={i}>
                    <div className="grid">
                      <div className="col-12 md:col-3 visiblemd consursoCon">
                        <a href={ac.imgUrl} target="_blank" rel="noreferrer">
                          <Image src={ac.imgUrl} alt={ac.name} />
                        </a>
                      </div>
                      <div className="col-12 md:col-9 pl-5 pr-5 pb-4">
                        <div className="col-12 visiblesm">
                          <a href={ac.imgUrl} target="_blank" rel="noreferrer">
                            <Image src={ac.imgUrl} alt={ac.name} />
                          </a>
                        </div>
                        <h3 className="m-0 text-center md:text-left">
                          {ac.name}
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ac.desc,
                          }}
                        ></div>

                        {ac.lines.map((li) => {
                          return (
                            <ul key={li.id} className="listLines">
                              <li>
                                <i className="pi pi-bolt"></i>
                                {li.desc}
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                    <hr className="separadorCom" />
                  </div>
                );
              })
            ) : (
              <>
                <LoaderSimple />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Lineas;
