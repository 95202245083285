import React from "react";
//PRIMEREACT
import { Dropdown } from "primereact/dropdown";
//COMPONENTES
//ESTILOS
import "./Campaign.css";
//FIREBASE

function History({ campanas, selectCampanas, setSelectCampanas }) {
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="campanasHis">
          <p className="tcampanas mb-1 mt-1">{option.name}</p>
          <p className="subtcampanas mt-0 mb-2">
            {option.perInit} / {option.perEnd} {option.year}
          </p>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="campanasHis">
        <p className="tcampanas mb-1 mt-1">{option.name}</p>
        <p className="subtcampanas mt-0 mb-2">
          {option.perInit} / {option.perEnd} {option.year}
        </p>
      </div>
    );
  };

  return (
    <>
      <Dropdown
        optionLabel="name"
        options={campanas}
        // id="campanas"
        // name="campanas"
        value={selectCampanas}
        onChange={(e) => {
          setSelectCampanas({ ...e.value });
        }}
        className="dropListC textWi"
        valueTemplate={selectedCountryTemplate}
        itemTemplate={countryOptionTemplate}
        placeholder="Seleccciona la campaña"
      />
    </>
  );
}

export default History;
