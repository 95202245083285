import React, { useState } from "react";
// PRIMEREACT
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
//FUNCIONES
import fInventory from "../../services/fInventory";
//MEDIA

export default function ModalSend({
  showMessageSend,
  actionHidden,
  setHeaderMessage,
  setColorIcon,
  setIcon,
  setMessage,
  setShowMessage,
  item,
  dataUser,
  configurations,
}) {
  const [submitted, setSubmitted] = useState(false);
  let acceptOrder = () => {
    setSubmitted(true);
    // console.log("dataUser", dataUser);
    let data = [];
    data.addressSend = dataUser.dataBranch.address;
    data.nameBranch = dataUser.dataBranch.nameBranch;
    data.mobilePhone = dataUser.mobilePhone;
    data.sendType = 3;
    data.to = configurations.eCommunications;
    data.to.push(dataUser.email);

    fInventory
      .addOrder(data, item, dataUser)
      .then((result) => {
        setHeaderMessage(result.headerMessage);
        setIcon(result.icon);
        setColorIcon(result.colorIcon);
        setMessage(result.message);
        setShowMessage(true);
      })
      .catch((reason) => {
        setHeaderMessage(reason.headerMessage);
        setIcon(reason.icon);
        setColorIcon(reason.colorIcon);
        setMessage(reason.message);
        setShowMessage(true);
        setSubmitted(false);
        console.log(reason);
      });
  };

  const showButton = fInventory.shouldShowButton();

  return (
    <Dialog
      visible={showMessageSend}
      onHide={() => {
        actionHidden();
      }}
      position="center"
      // footer={dialogFooter}
      showHeader
      style={{ width: "50%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={<h3 className="m-0 text-center">El pedido será enviado</h3>}
    >
      <div className="grid grid-nogutter">
        <div className="col-10 col-offset-1 p-4">
          <p className="nameItem text-left m-0 visiblemd">
            <b>A la siguiente sucursal:</b>
            {dataUser && dataUser.dataBranch
              ? dataUser.dataBranch.address
              : "---"}
          </p>
          <p className="nameItem text-left m-0 visiblemd">
            <b>Dirección:</b>{" "}
            {dataUser && dataUser.dataBranch
              ? dataUser.dataBranch.nameBranch
              : "---"}
          </p>
          <p className="nameItem text-left m-0 visiblemd">
            <b>Nombre del responsable:</b>
            {dataUser && dataUser.dataBranch ? dataUser.fullName : "---"}
          </p>
          <p className="nameItem text-left m-0 visiblemd">
            <b>Número de teléfono:</b>{" "}
            {dataUser && dataUser.dataBranch ? dataUser.mobilePhone : "---"}
          </p>
          {showButton && (
            <div className="col-12 md:col-4 md:col-offset-4">
              <Button
                className="p-button-brilla"
                type="button"
                disabled={submitted}
                onClick={() => {
                  acceptOrder();
                }}
              >
                Enviar
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
