import React, { useState, useEffect } from "react";
//COMPONENTES
import UserComments from "./UserComments";
import LoaderSimple from "../LoaderSimple";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//MEDIA
//PRIMEREACT
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
//SERVICES
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";
//FIREBASE
import { collection, doc, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

function Comments({ dataUser, selectCampanas, comentLimit }) {
  const [submitted, setSubmitted] = useState(false);
  const [userComent, setUserComent] = useState(null);

  useEffect(() => {
    const q = doc(db, "campaign", selectCampanas.id);
    const qSub = collection(q, "comments");

    const unlistenI = onSnapshot(
      qSub,
      orderBy("date", "asc"),
      // limit(comentLimit),
      (docs) => {
        let comentariosCampa = [];

        docs.forEach((doc) => {
          comentariosCampa.push({ ...doc.data(), id: doc.id });
        });
        setUserComent(comentariosCampa);
      }
    );
    return () => {
      unlistenI();
    };
  }, [selectCampanas.id]);

  const formik = useFormik({
    initialValues: {
      comments: "",
    },
    validationSchema: Yup.object({
      comments: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const addcoment = await fglobales.addComments(
          "campaign",
          selectCampanas,
          formData,
          dataUser
        );

        if (addcoment) {
          setSubmitted(false);
          formik.resetForm();
        }
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className='p-error'>{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <div className='col-12 p-4'>
        <h3 className='mb-2'>Déjanos un comentario</h3>
        <form
          onSubmit={formik.handleSubmit}
          className='p-fluid'>
          <div className='grid'>
            <div className='col-12'>
              <InputTextarea
                placeholder='Escribe aquí tu comentario...'
                value={formik.values.comments}
                id='comments'
                name='comments'
                onChange={formik.handleChange}
                rows={5}
                cols={20}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("comments"),
                  },
                  "commentsText"
                )}
                disabled={submitted}
              />
              {getFormErrorMessage("comments")}
            </div>
            <div className='col-12 md:col-5 md:col-offset-7 lg:col-3 md:col-offset-9'>
              <Button
                className='p-button-brilla'
                disabled={submitted}
                type='submit'>
                Publicar
              </Button>
            </div>
          </div>
        </form>
      </div>

      <div className='col-12 md:p-5 md:pt-2'>
        {userComent ? (
          userComent
            .sort((a, b) => (a.date > b.date ? -1 : 1))
            .slice(0, comentLimit)
            .map((co, i) => {
              return (
                <div key={i}>
                  <UserComments
                    userComent={co}
                    dataUser={dataUser}
                  />
                </div>
              );
            })
        ) : (
          <>
            <LoaderSimple />
          </>
        )}
      </div>
    </>
  );
}

export default Comments;
