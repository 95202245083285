import React, { useState } from "react";
//COMPONENTES
import Messages from "../Others/Messages";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
//SERVICES
import { toast } from "react-toastify";
import authentication from "../../services/authentication";
//FIREBASE
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

function RecuperarPass({ changeControl }) {
  const [submitted, setSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState(false);
  const [icon, setIcon] = useState(false);
  const [headerMessage, setHeaderMessage] = useState("¡Felicidades!");
  const [message, setMessage] = useState("Tu Register");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
    }),
    onSubmit: async (formData) => {
      try {
        sendPasswordResetEmail(auth, formData.email)
          .then(() => {
            setIcon("pi-check-circle");
            setColorIcon("secondary-color");
            setHeaderMessage("¡Atención!");
            setMessage(
              "Envíamos un correo electrónico con los pasos para restablecer tu contraseña."
            );
            setShowMessage(true);
          })
          .catch((error) => {
            const errorCode = error.code;
            setIcon("pi-times");
            setColorIcon("primary-color");
            setHeaderMessage("¡Atención!");
            setMessage(authentication.verifyErrorCode(errorCode));
            setShowMessage(true);
            // toast.error(authentication.verifyErrorCode(errorCode));
            console.log(error);
          });
      } catch (error) {
        toast.error(error);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="col-12">
        <h2>Recuperar contraseña</h2>
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="grid">
            <div className="col-12 sinPT">
              <p className="pForm">Correo electrónico:</p>
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={submitted}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("email"),
                  },
                  "textForm"
                )}
              />

              {getFormErrorMessage("email")}
            </div>

            <div className="col-12 md:col-4 md:col-offset-4">
              <Button
                className="p-button-brilla"
                type="submit"
                disabled={submitted}
              >
                Enviar
              </Button>
            </div>
          </div>
        </form>
      </div>

      <p
        className="m-0 pl-2 pb-2 recuperarPass"
        onClick={() => {
          changeControl();
        }}
      >
        Iniciar sesión
      </p>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default RecuperarPass;
