import React, { useState, useEffect } from "react";
//PRIMEREACT
import { Image } from "primereact/image";
import { Button } from "primereact/button";
//COMPONENTES
// import Banner from "../Banner";
import LoaderSimple from "../LoaderSimple";
import History from "./History";
import HistoryMovil from "./HistoryMovil";
import Comments from "./Comments";
import Contest from "./Contest";
//ESTILOS
import "./Campaign.css";
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//FIREBASE
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

function Campaign() {
  const { dataUser } = useUsuario();
  const [campanas, setCampanas] = useState(null);
  const [selectCampanas, setSelectCampanas] = useState(null);
  const [comentLimit, setComentLimit] = useState(50);

  useEffect(() => {
    const q = query(collection(db, "campaign"), orderBy("date", "desc"));
    const unlistenI = onSnapshot(q, (docs) => {
      let campanasHistory = [];
      let campanasActive = [];

      docs.forEach((doc) => {
        if (doc.data().status === true) {
          campanasHistory.push({ ...doc.data(), id: doc.id });
          campanasActive.push({ ...doc.data(), id: doc.id });
        }
      });

      setCampanas(campanasHistory);
      setSelectCampanas(campanasActive[0]);
    });
    return () => {
      unlistenI();
    };
  }, []);

  return (
    <>
      {/* <Banner bannerName={"campaign"} /> */}
      <div className='grid grid-nogutter container-principal'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1 className='mt-0'>Campaña actual</h1>
          <br />
          <div className='grid'>
            <div className='col-12 contentForm'>
              {campanas ? (
                <>
                  <div className='grid'>
                    <div className='col-12 md:col-3'>
                      <h3 className='text-center m-0'>Campañas</h3>
                      {campanas.map((c, i) => {
                        return (
                          <div
                            key={i}
                            className='visiblemd'>
                            <History
                              campana={c}
                              name={c.name}
                              perInit={c.perInit}
                              perEnd={c.perEnd}
                              year={c.year}
                              setSelectCampanas={setSelectCampanas}
                              selectCampanas={selectCampanas}
                              id={c.id}
                            />
                          </div>
                        );
                      })}

                      <div className='visiblesm'>
                        <HistoryMovil
                          campanas={campanas}
                          selectCampanas={selectCampanas}
                          setSelectCampanas={setSelectCampanas}
                        />
                      </div>
                    </div>

                    {selectCampanas ? (
                      <>
                        <div className='col-12 md:col-9 borderCanpaña'>
                          <div className='grid-nogutter'>
                            <div className='col-12 md:col-8'>
                              <h3 className='text-center mt-1 mb-2'>
                                {selectCampanas.name}
                              </h3>
                            </div>

                            <div className='grid visiblemd'>
                              <div className='col-12 md:col-8 pl-5 pr-4'>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: selectCampanas.desc,
                                  }}></div>
                              </div>
                              <div className='col-12 md:col-4'>
                                <Image
                                  src={selectCampanas.logoCam}
                                  alt={selectCampanas.name}
                                />
                                <p className='tcampanas text-center mt-2'>
                                  {selectCampanas.perInit} /{" "}
                                  {selectCampanas.perEnd} {selectCampanas.year}
                                </p>
                              </div>
                            </div>

                            <div className='grid visiblesm'>
                              <div className='col-12 md:col-8'>
                                <div className='col-12 md:col-4'>
                                  <Image
                                    src={selectCampanas.logoCam}
                                    alt={selectCampanas.name}
                                  />

                                  <p className='tcampanas text-center mt-2 mb-0'>
                                    {selectCampanas.perInit} /{" "}
                                    {selectCampanas.perEnd}{" "}
                                    {selectCampanas.year}
                                  </p>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: selectCampanas.desc,
                                  }}></div>
                              </div>
                            </div>
                          </div>

                          <div className='grid'>
                            <Comments
                              dataUser={dataUser}
                              selectCampanas={selectCampanas}
                              comentLimit={comentLimit}
                              setComentLimit={setComentLimit}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <>
                  <LoaderSimple />
                </>
              )}
              <div className='grid mt-8'>
                <div className='col-12 md:col-4 md:col-offset-4'>
                  <Button
                    className='p-button-vermas'
                    type='submit'
                    onClick={() => {
                      setComentLimit(comentLimit * 2);
                    }}>
                    Ver más
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1 className='mt-0'>Concursos vigentes en la campaña</h1>
          <div className='grid'>
            <div className='col-12 contentForm'>
              {selectCampanas && selectCampanas.id ? (
                <Contest
                  selectCampanas={selectCampanas}
                  dataUser={dataUser}
                />
              ) : (
                <LoaderSimple />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Campaign;
