import React from "react";
//PRIMEREACT
import { Image } from "primereact/image";
//COMPONENTES
//ESTILOS
import "./Campaign.css";
//FIREBASE

function Contest({ contestData, dataUser }) {
  return (
    <>
      {dataUser.districtId === contestData.districtId ||
      contestData.districtId === "5" ? (
        <div className="grid">
          <div className="col-12 md:col-3 visiblemd consursoCon">
            <Image src={contestData.logoCon} alt="Imagen Concurso" />
          </div>
          <div className="col-12 md:col-9 pl-5 pr-5 pb-4">
            <div className="col-12 visiblesm">
              <Image src={contestData.logoCon} alt="Imagen Concurso" />
            </div>
            <h3 className="m-0 text-center md:text-left">{contestData.name}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: contestData.desc,
              }}
            ></div>
            <p className="tcampanas m-0">
              {contestData.status ? (
                <span className="activa">Activa: </span>
              ) : (
                <span className="inactiva">Inactiva: </span>
              )}
              {contestData.dateInicio} - {contestData.dateFinal}
            </p>
            <p className="tcampanas m-0">
              <b>Distrito:</b> {contestData.districtDesc}
            </p>
            <p className="tcampanas m-0">
              <b>Línea:</b> {contestData.lines}
            </p>
            <p className="tcampanas m-0">
              <b>Premio:</b> {contestData.reward}
            </p>
          </div>

          <hr className="separadorCom" />
        </div>
      ) : (
        <div className="col-12">
          <h3>No existen consursos que apliquen a este distrito</h3>
        </div>
      )}
    </>
  );
}

export default Contest;
