import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
//MEDIA

export default function ModalOrder({ showOrder, actionHidden, orders }) {
  return (
    <Dialog
      visible={showOrder}
      onHide={() => actionHidden()}
      position="center"
      showHeader
      style={{ width: "60%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={<h2 className="m-0 text-center">Tus olimpos redimidos</h2>}
    >
      <div className="grid grid-nogutter">
        <div className="col-10 col-offset-1">
          {orders && orders.length === 0 ? (
            <h3 className="text-center">
              En este momento no has redimido ningun premio
            </h3>
          ) : orders.length > 0 ? (
            orders.map((or, orderIndex) => {
              return (
                <div
                  className="order-container"
                  style={{
                    border: "1px solid #cccccc",
                    padding: "10px",
                    marginBottom: "30px",
                  }}
                  key={or.id}
                >
                  <div className="flex justify-content-between align-items-center flex-row">
                    <h3>Orden {orders.length - orderIndex}</h3>
                    <p className="orderDesc font-bold">{or.dateBuy}</p>
                  </div>

                  {or.items.map((item, index) => (
                    <div
                      className={`grid pb-4 pt-6 ${
                        or.items.length !== index + 1 && " borderB"
                      }`}
                      key={`${or.id}-${index}`}
                    >
                      {/*Vista en escritorio*/}
                      <div className="col-12 md:col-3 img-responsive visiblemd">
                        <img src={item.img} alt={item.name} />
                      </div>
                      <div className="col-12 md:col-9 visiblemd">
                        <div className="grid pl-3">
                          <div className="col-12 pb-0">
                            <p className="nameOrder mt-0">{item.name}</p>
                          </div>

                          <div className="col-12 pt-0">
                            <p className="orderDesc m-0">{item.description}</p>
                          </div>

                          <div className="col-12 pt-0">
                            <p className="orderDesc m-0">
                              <span className="directionOrder">
                                Dirección de envío:{" "}
                              </span>
                              {or.addressSend}
                            </p>
                          </div>

                          <div className="col-12">
                            <p className="olimposOrder m-0">
                              {item.cost} Olimpos
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Resto del código...*/}
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <LoaderSimple />
          )}
        </div>
      </div>
    </Dialog>
  );
}
