import React from "react";

function InsigniActual({ insigniasData, dataWinners }) {
  return (
    <div>
      {insigniasData.map((ins, i) =>
        ins.id === dataWinners.insigniaId ? (
          <div key={i} className="col-8 col-offset-2 md:col-6 p-0">
            <img src={ins.imgUrl} alt={ins.name} />
          </div>
        ) : null
      )}
    </div>
  );
}

export default InsigniActual;
