import React, { useState } from "react";
import "./Register.css";
//COMPONENTES
import PDC from "../Others/PDC";
import Messages from "../Others/Messages";
import ModalAdviser from "./ModalAdviser";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
//SERVICES
import regex from "../../services/regex";
import { toast } from "react-toastify";
import authentication from "../../services/authentication";

function Register({
  dniExist,
  dataUserPre,
  alliesData,
  branchesData,
  linesData,
  configurations,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [showMessageAdviser, setShowMessageAdviser] = useState(false);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      role: "",
      names: dataUserPre.names ? dataUserPre.names : "",
      surnames: dataUserPre.surnames ? dataUserPre.surnames : "",
      dni: "",
      // fullName: "",
      // branches: "",
      mobilePhone: "",
      otherPhone: "",
      email: "",
      address: "",
      country: {
        id: "44",
        iso2: "CO",
        iso3: "COL",
        name: "Colombia",
        nom: "Colombie",
        nombre: "Colombia",
        phone_code: "57",
      },
      department: "",
      municipality: "",
      dateBirth: "",
      sizeShirt: "",
      lines: [],
      //movilidad: "",
      // electrodomesticos: "",
      // tecnologia: "",
      // muebles: "",
      // educacion: "",
      // gasodomesticos: "",
      // materialesConstruccion: "",
      // otro: "",
      history: "",
      password: "",
      passwordConfirmation: "",
      ppd: "",
      tdp: "",
      olimpos: 0,
      events: [],
    },
    validationSchema: Yup.object({
      // fullName: Yup.string().required("El nombre es obligatorio"),
      names: Yup.string().required("Los nombres son obligatorios"),
      surnames: Yup.string().required("Los apellidos son obligatorios"),
      dni: Yup.number().required("El documento de identidad es obligatorio"),
      role: Yup.object().required("Seleccionar el rol es obligatorio"),
      // branches: Yup.object().required("Seleccionar la sucursal es obligatorio"),
      mobilePhone: Yup.string()
        .matches(regex.mobilePhone, "El campo debe tener 10 dígitos")
        .required("Este campo es requerido"),
      email: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
      address: Yup.string().required("La dirección es obligatoria"),
      department: Yup.object().required("El departamento es obligatorio"),
      municipality: Yup.object().required("La Ciudad/Municipio es obligatorio"),
      // sizeShirt: Yup.object().required("La talla de la camisa es obligatoria"),
      password: Yup.string().required("La contraseña es obligatoria"),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben coincidir"
      ),
      ppd: Yup.boolean()
        .required("Los términos y condiciones deben ser aceptados.")
        .oneOf([true], "Los términos y condiciones deben ser aceptados."),
      tdp: Yup.boolean()
        .required("El tratamiento de datos personales dede ser aceptado.")
        .oneOf([true], "El tratamiento de datos personales dede ser aceptado."),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        authentication
          .signUpWithEmailAddressAndPassword(
            formData.email,
            formData.password,
            formData,
            dataUserPre
          )
          .then((result) => {
            toast.success(result);
            setSubmitted(false);
            formik.resetForm();
          })
          .catch((error) => {
            setSubmitted(false);
            toast.error(error);
            console.log(error);
          });
      } catch (error) {
        toast.error(error);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className='p-error'>{formik.errors[name]}</small>
      )
    );
  };

  const roles = [
    { name: "Administrador", code: 0 },
    { name: "Coordinador", code: 1 },
    { name: "Gerente", code: 2 },
    { name: "Líder de punto", code: 3 },
  ];

  const onLinesChange = (e) => {
    let selectedLines = [
      ...(formik.values.lines === "" ? [] : formik.values.lines),
    ];
    if (e.checked) selectedLines.push(e.value);
    else selectedLines.splice(selectedLines.indexOf(e.value), 1);
    formik.setFieldValue("lines", selectedLines);
  };

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  let maxDate = new Date();
  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  const actionHiddenMessageAdviser = () => {
    setShowMessageAdviser(false);
    hideDialogAdviser();
  };

  const hideDialogAdviser = () => {
    setShowMessageAdviser(false);
  };

  const openModal = () => {
    setShowMessageAdviser(true);
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  const openTerminos = (data) => {
    setHeaderMessage("Acepto términos y condiciones.");
    setColorIcon("secondary-color");
    setIcon("ppi-file-pdf");
    setMessage(
      <>
        {data === 0 ? (
          <a
            href={configurations.termsAndConditions}
            target='_blank'
            rel='noopener noreferrer'>
            Terminos y Condiciones
          </a>
        ) : (
          <a
            href={configurations.habeasData}
            target='_blank'
            rel='noopener noreferrer'>
            Tratamiento de datos personales
          </a>
        )}
      </>
    );
    setShowMessage(true);
  };

  return (
    <>
      {/* <h2>Registrate y empieza a ganar poderes</h2> */}
      <form
        onSubmit={formik.handleSubmit}
        className='p-fluid'>
        <div className='grid'>
          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Aliado: </p>
            <div className='aliado'>
              {alliesData
                ? alliesData.map((ally) => {
                    return <p key={ally.alliedCode}>{ally.allyName}</p>;
                  })
                : ""}
            </div>
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Sucursal a la que represento: *</p>
            <div className='aliado'>
              {branchesData
                ? branchesData.map((bra) => {
                    return <p key={bra.branchCode}>{bra.nameBranch}</p>;
                  })
                : ""}
            </div>
            <br />
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Soy un: *</p>
            {/* <InputText
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              disabled={dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("role"),
                },
                "textForm"
              )}
            />
            {getFormErrorMessage("role")} */}

            <Dropdown
              optionLabel='name'
              options={roles}
              id='role'
              name='role'
              value={formik.values.role}
              onChange={(e) => {
                formik.setFieldValue("role", e.value);
              }}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("role"),
                },
                "textForm"
              )}
              disabled={submitted || !dniExist}
            />
            {getFormErrorMessage("role")}
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Cédula: </p>

            <InputText
              id='dni'
              name='dni'
              type='tel'
              value={formik.values.dni}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("dni"),
                },
                "textForm"
              )}
            />
            {getFormErrorMessage("dni")}
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Nombres: *</p>
            <InputText
              id='names'
              name='names'
              value={formik.values.names}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("names"),
                },
                "textForm"
              )}
            />
            {getFormErrorMessage("names")}
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Apellidos: *</p>
            <InputText
              id='surnames'
              name='surnames'
              value={formik.values.surnames}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("surnames"),
                },
                "textForm"
              )}
            />
            {getFormErrorMessage("surnames")}
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Celular: *</p>
            <InputMask
              id='mobilePhone'
              name='mobilePhone'
              mask='(999) 999-9999'
              placeholder='(999) 999-9999'
              value={formik.values.mobilePhone}
              onChange={(e) => formik.setFieldValue("mobilePhone", e.value)}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("mobilePhone"),
                },
                "textForm"
              )}
              type='tel'></InputMask>
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Otro teléfono: </p>
            <InputMask
              id='otherPhone'
              name='otherPhone'
              mask='(999) 999-9999'
              placeholder='(999) 999-9999'
              value={formik.values.otherPhone}
              onChange={(e) => formik.setFieldValue("otherPhone", e.value)}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("otherPhone"),
                },
                "textForm"
              )}
              type='tel'></InputMask>
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Correo electrónico: *</p>
            <InputText
              id='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("email"),
                },
                "textForm"
              )}
            />

            {getFormErrorMessage("email")}
          </div>

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Dirección de la sucursal: *</p>
            <InputText
              id='address'
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("address"),
                },
                "textForm"
              )}
            />
            {getFormErrorMessage("address")}
          </div>

          <PDC
            submitted={submitted}
            formik={formik}
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
          />

          <div className='col-12 md:col-6 sinPT'>
            <p className='pForm'>Fecha de cumpleaños: </p>
            <span className='p-float-label p-input-icon-right textForm'>
              <i className='textFormIcon pi pi-calendar' />
              <Calendar
                id='dateBirth'
                name='dateBirth'
                value={formik.values.dateBirth}
                onChange={formik.handleChange}
                disabled={submitted || !dniExist}
                dateFormat='dd/mm/yy'
                // minDate={minDate}
                maxDate={maxDate}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("dateBirth"),
                  },
                  "textForm"
                )}
              />
            </span>
            {getFormErrorMessage("dateBirth")}
          </div>

          {/* <div className="col-12 md:col-6 sinPT">
            <p className="pForm">Talla de camisa: *</p>
            <Dropdown
              optionLabel="name"
              options={tallas}
              id="sizeShirt"
              name="sizeShirt"
              value={formik.values.sizeShirt}
              onChange={(e) => {
                formik.setFieldValue("sizeShirt", e.value);
              }}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("sizeShirt"),
                },
                "textForm"
              )}
              disabled={submitted || !dniExist}
            />
            {getFormErrorMessage("sizeShirt")}
            <br />
          </div> */}

          <div className='col-12 sinPT'>
            <p className='pForm'>
              Selecciona las líneas que comercializa tu punto de venta:
            </p>
          </div>

          <div className='col-12 md:col-6 sinPT'>
            {linesData.map((lin) => {
              return (
                <div
                  className='field-checkbox'
                  key={lin.id}>
                  <Checkbox
                    inputId={lin.id}
                    name={lin.desc}
                    value={lin}
                    onChange={onLinesChange}
                    checked={formik.values.lines?.some(
                      (item) => item.id === lin.id
                    )}
                    disabled={submitted || !dniExist}
                  />
                  <label htmlFor={lin.desc}>{lin.desc}</label>
                </div>
              );
            })}
          </div>

          <div className='col-12 sinPT'>
            <p className='pForm'>
              Cuéntanos por qué en tu sucursal se consideran unos Titanes
              Brilla:
            </p>
            <InputTextarea
              value={formik.values.history}
              id='history'
              name='history'
              onChange={formik.handleChange}
              rows={5}
              cols={30}
              autoResize
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("history"),
                },
                "textForm"
              )}
              disabled={submitted || !dniExist}
            />
            {getFormErrorMessage("history")}
          </div>

          <div className='col-12 sinPT'>
            <p className='pForm'>Escoge una clave para tu registro : *</p>
            <Password
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("password"),
                },
                "textForm"
              )}
              toggleMask
              autoComplete='off'
            />
            {getFormErrorMessage("password")}
          </div>

          <div className='col-12 sinPT'>
            <p className='pForm'>Confirma tu clave: *</p>
            <Password
              name='passwordConfirmation'
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              disabled={submitted || !dniExist}
              className={classNames(
                {
                  "p-invalid": isFormFieldValid("passwordConfirmation"),
                },
                "textForm"
              )}
              toggleMask
              autoComplete='off'
            />
            {getFormErrorMessage("passwordConfirmation")}
          </div>

          <div className='col-12 sinPT'>
            <p className='pForm'>
              Registra al equipo de titanes de tu sucursal
            </p>
            <Button
              className='p-button-brilla'
              disabled={submitted || !dniExist}
              type='button'
              onClick={() => {
                openModal();
              }}>
              Agregar asesor
            </Button>
          </div>

          <div className='col-12 pb-0'>
            <div className='field-checkbox'>
              <Checkbox
                onChange={(e) => formik.setFieldValue("ppd", e.checked)}
                disabled={submitted || !dniExist}
                checked={formik.values.ppd}
                inputId='ppd'
              />
              <label
                htmlFor='binary'
                className={classNames(
                  {
                    "p-error": isFormFieldValid("ppd"),
                  },
                  "checked"
                )}
                onClick={() => {
                  openTerminos(0);
                }}>
                Acepto términos y condiciones.
              </label>
            </div>
            {getFormErrorMessage("ppd")}
          </div>

          <div className='col-12 pt-0'>
            <div className='field-checkbox'>
              <Checkbox
                onChange={(e) => formik.setFieldValue("tdp", e.checked)}
                disabled={submitted || !dniExist}
                checked={formik.values.tdp}
                inputId='tdp'
              />
              <label
                htmlFor='binary'
                className={classNames(
                  {
                    "p-error": isFormFieldValid("tdp"),
                  },
                  "checked"
                )}
                onClick={() => {
                  openTerminos(1);
                }}>
                Acepto el tratamiento de mis datos personales.
              </label>
            </div>
            {getFormErrorMessage("ppd")}
          </div>
          <div className='col-12 md:col-4 md:col-offset-4'>
            <Button
              className='p-button-brilla'
              disabled={submitted || !dniExist}
              type='submit'>
              Registrarse
            </Button>
          </div>
        </div>
      </form>

      <ModalAdviser
        showMessageAdviser={showMessageAdviser}
        actionHidden={actionHiddenMessageAdviser}
        dataUserPre={dataUserPre}
      />

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default Register;
