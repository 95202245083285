import React, { useState } from "react";
//COMPONENTES
import Messages from "../Others/Messages";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
//SERVICES
import regex from "../../services/regex";
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";

function ValidationDNI({
  setDniExist,
  dniExist,
  setDataUserPre,
  setAlliesData,
  setDranchesData,
  setLinesData,
}) {
  const [submitted, setSubmitted] = useState(false);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      codeAccess: "",
    },
    validationSchema: Yup.object({
      codeAccess: Yup.string()
        .matches(regex.codeAccess, "El campo debe tener solo dígitos")
        .required("Este campo es requerido"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const docUser = await fglobales.getExistNoId(
          "accessList",
          "codeAccess",
          formData.codeAccess
        );

        if (!docUser) {
          setIcon("pi-exclamation-triangle");
          setColorIcon("secondary-color");
          setHeaderMessage("¡Atención! tu consulta ha fallado");
          setMessage(
            <>
              Tu código de acceso no se encuentra registrado en nuestra base de
              datos favor contactar a soporte{" "}
              <a href="mailto:soportebrilla@brilla.com">
                soportebrilla@brilla.com
              </a>
            </>
          );
          setShowMessage(true);
          setSubmitted(false);
          return;
        }

        const docUserd = await fglobales.getUsersDatos(
          "accessList",
          "codeAccess",
          formData.codeAccess
        );

        const docAlly = await fglobales.getDatosABC(
          "allies",
          "alliedCode",
          docUserd.allies[0]
        );

        const docBran = await fglobales.getDatosABC(
          "branches",
          "branchCode",
          docUserd.branches[0]
        );

        const docLineas = await fglobales.getDatosABC("lines", "status", true);

        if (docUserd.resgister & (docAlly.length > 0) & (docBran.length > 0)) {
          setIcon("pi-exclamation-triangle");
          setColorIcon("secondary-color");
          setHeaderMessage("¡Atención!");
          setMessage(
            "Tus datos ya se encuentran registrados, favor inicia sesión"
          );
          setShowMessage(true);
          return;
        }

        setDataUserPre(docUserd);
        setAlliesData(docAlly);
        setDranchesData(docBran);
        setDniExist(docUser);
        setLinesData(docLineas);
        setSubmitted(false);
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="col-12">
        <h2>
          Regístrate y empieza <br />a ganar poderes
        </h2>
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="grid">
            <div className="col-12 sinP">
              <p className="mensajeL">
                Consulta aquí si tu código de acceso aparece pre-registrado en
                nuestra base de datos.
              </p>
            </div>

            <div className="col-12 sinP">
              <p className="pForm2">Código de acceso: *</p>
            </div>
            <div className="col-12 md:col-9">
              <InputText
                id="codeAccess"
                name="codeAccess"
                value={formik.values.codeAccess}
                onChange={formik.handleChange}
                disabled={submitted || dniExist}
                type="tel"
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("codeAccess"),
                  },
                  "textForm"
                )}
              />
              {getFormErrorMessage("codeAccess")}
            </div>
            <div className="col-12 md:col-3">
              <Button
                className="p-button-brilla"
                type="submit"
                disabled={dniExist}
              >
                Consultar
              </Button>
            </div>
          </div>
        </form>
      </div>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default ValidationDNI;
