import React, { useState } from "react";
//RUTAS
import { Link, NavLink } from "react-router-dom";
//ESTILOS
import "./Layout.css";
//MEDIA
import TitanesLogo from "../../assets/images/titanes.png";
import UserI from "../../assets/images/imgUser.png";
//COMPONENTES
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//SERVICIOS
import fanalytics from "../../services/fanalytics";

function Header() {
  const { dataUser, isLogged } = useUsuario();
  const [menu, setMenu] = useState(false);

  return (
    <>
      <nav className="menuTitanes">
        <input
          type="checkbox"
          id="check"
          onClick={() => {
            setMenu(!menu);
          }}
        />
        <label htmlFor="check" className="checkbtn">
          {menu ? (
            <i className="pi pi-times"></i>
          ) : (
            <i className="pi pi-bars"></i>
          )}
        </label>
        <Link
          to="/home"
          className="enlace"
          onClick={() => {
            fanalytics.logEvents(
              "navigation_click",
              "button",
              "logo_icon_menu"
            );
          }}
        >
          <img src={TitanesLogo} alt="TitanesLogo" className="titanesLogo" />
        </Link>
        <ul>
          {!isLogged() ? (
            <>
              <li className="sinUserMe">
                <NavLink
                  to="/home"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_home"
                    );
                  }}
                >
                  TITANES
                </NavLink>
              </li>
              <li className="sinUserMe">
                <NavLink
                  to="/registrate"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_registro"
                    );
                  }}
                >
                  REGÍSTRATE
                </NavLink>
              </li>
              <li className="sinUserMe">
                <NavLink
                  to="/login"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_login"
                    );
                  }}
                >
                  INICIO DE SESIÓN
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink
                  to="/home"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_home_logged"
                    );
                  }}
                >
                  TITANES
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/notibrilla"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_notibrilla"
                    );
                  }}
                >
                  NOTIBRILLA
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/concursos"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_concursos"
                    );
                  }}
                >
                  CONCURSOS Y CAMPAÑAS
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/ganadores"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_ganadores"
                    );
                  }}
                >
                  GANADORES Y EVENTOS
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/portal"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_galeria"
                    );
                  }}
                >
                  GALERÍA
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/lineas"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_lineas"
                    );
                  }}
                >
                  LÍNEAS
                </NavLink>
              </li>

              {/* <li className="visiblesm overflow-hidden">
                <NavLink to="/perfil">{dataUser.fullName}</NavLink>
              </li> */}

              <li className="ultimo">
                <NavLink
                  to="/perfil"
                  onClick={() => {
                    fanalytics.logEvents(
                      "navigation_click",
                      "button",
                      "item_perfil"
                    );
                  }}
                >
                  {isLogged() && dataUser.userImg ? (
                    <img src={dataUser.userImg} alt="img" />
                  ) : (
                    <img src={UserI} alt="img" />
                  )}
                  <span className="miperfil">Mi Sucursal</span>
                </NavLink>
              </li>

              {/* <li className="ultimo movilMenu">
                <NavLink to="/perfil">
                  {dataUser && dataUser.userImg ? (
                    <img src={dataUser.userImg} alt="img" />
                  ) : (
                    <img src={UserI} alt="img" />
                  )}{" "}
                  <span className="miperfil">Mi perfil</span>
                  <br />
                  <br />
                </NavLink>
              </li> */}
            </>
          )}
        </ul>
      </nav>
    </>
  );
}

export default Header;
