import React, { createContext, useState, useEffect, useContext } from "react";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const CartContext = createContext();
const localStorageKey = "cart";

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const localData = localStorage.getItem(localStorageKey);
    return localData ? JSON.parse(localData) : [];
  });
  const [inventory, setInventory] = useState([]);
  const [loadingInventory, setLoadingInventory] = useState(true);

  const addToCart = (item) => {
    setCart((prevCart) => [...prevCart, { ...item, quantity: 1 }]);
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
  };

  const setQuantity = (itemId, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId ? { ...item, quantity: quantity } : item
      )
    );
  };

  const isItemInCart = (itemId) => {
    return cart.some((item) => item.id === itemId);
  };

  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalCost = () => {
    return cart.reduce((total, item) => total + item.quantity * item.cost, 0);
  };
  const resetCart = () => {
    setCart([]);
  };

  useEffect(() => {
    const fetchInventory = async () => {
      const q = query(collection(db, "inventory"), where("quantity", ">", 0));
      const unlistenI = onSnapshot(q, (docs) => {
        let data = [];

        docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });

        // Filtrar los datos por status true después de obtenerlos
        const filteredData = data.filter((item) => item.status === true);

        // Ordenar los datos filtrados
        setInventory(filteredData.sort(({ order: a }, { order: b }) => a - b));
        setLoadingInventory(false);
      });
      return () => {
        unlistenI();
      };
    };

    fetchInventory();
  }, []);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === localStorageKey) {
        setCart(JSON.parse(e.newValue));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        setQuantity,
        isItemInCart,
        getTotalItems,
        getTotalCost,
        resetCart,
        inventory,
        loadingInventory,
      }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  return useContext(CartContext);
}
