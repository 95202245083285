import React, { useState, useEffect } from "react";
import "../Register/Register.css";
//COMPONENTES
import PDC from "../Others/PDC";
import Messages from "../Others/Messages";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
// import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
//SERVICES
import regex from "../../services/regex";
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";
//CONTEXT
import { useUsuario } from "../../context/usuario-context.jsx";
//ROUTER
import { useNavigate } from "react-router-dom";

function EditUser() {
  const { dataUser, resetDataUser } = useUsuario();
  const [submitted, setSubmitted] = useState(false);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--
  const [alliesData, setAlliesData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [linesData, setLinesData] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const miFuncionAsincrona = async () => {
      try {
        const docAlly = await fglobales.getDatosABC(
          "allies",
          "alliedCode",
          dataUser.allies[0]
        );

        const docBran = await fglobales.getDatosAB(
          "branches",
          "branchCode",
          dataUser.branches[0]
        );

        const docLines = await fglobales.getDatosAB("lines", "status", true);

        setAlliesData(docAlly);
        setBranchesData(docBran);
        setLinesData(docLines);
      } catch (error) {
        console.log(error);
      }
    };

    miFuncionAsincrona();
  }, [dataUser]);

  const formik = useFormik({
    initialValues: {
      role: dataUser.role ? dataUser.role : "",
      // fullName: dataUser.fullName ? dataUser.fullName : "",
      names: dataUser.names ? dataUser.names : "",
      surnames: dataUser.surnames ? dataUser.surnames : "",
      mobilePhone: dataUser.mobilePhone ? dataUser.mobilePhone : "",
      otherPhone: dataUser.otherPhone ? dataUser.otherPhone : "",
      email: dataUser.email ? dataUser.email : "",
      address: dataUser.address ? dataUser.address : "",
      country: {
        id: "44",
        iso2: "CO",
        iso3: "COL",
        name: "Colombia",
        nom: "Colombie",
        nombre: "Colombia",
        phone_code: "57",
      },
      department: dataUser.department ? dataUser.department : "",
      municipality: dataUser.municipality ? dataUser.municipality : "",
      dateBirth: dataUser.dateBirth ? dataUser.dateBirth.toDate() : "",
      lines: dataUser.lines ? dataUser.lines : "",
      history: dataUser.history ? dataUser.history : "",
    },
    validationSchema: Yup.object({
      // fullName: Yup.string().required("El nombre es obligatorio"),
      names: Yup.string().required("Los nombres son obligatorios"),
      surnames: Yup.string().required("Los apellidos son obligatorios"),
      mobilePhone: Yup.string()
        .matches(regex.mobilePhone, "El campo debe tener 10 dígitos")
        .required("Este campo es requerido"),
      email: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
      address: Yup.string().required("La dirección es obligatoria"),
      department: Yup.object().required("El departamento es obligatorio"),
      municipality: Yup.object().required("La Ciudad/Municipio es obligatorio"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const docUserEdit = await fglobales.updateDoc(
          "usersClients",
          dataUser.userId,
          formData
        );

        if (docUserEdit) {
          setIcon("pi-check-circle");
          setColorIcon("green-500");
          setHeaderMessage("¡Felicidades!");
          setMessage("Tus datos han sido actualizados de manera exitosa");
          setShowMessage(true);
        } else {
          setIcon("pi-exclamation-triangle");
          setColorIcon("secondary-color");
          setHeaderMessage("¡Atención!");
          setMessage("Tu actualización de datos ha fallado");
          setShowMessage(true);
        }

        setSubmitted(false);
      } catch (error) {
        toast.error(error);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
    resetDataUser();
    navigate(`/perfil`);
  };

  // const tallas = [
  //   { name: "XS", code: 0 },
  //   { name: "S", code: 1 },
  //   { name: "M", code: 2 },
  //   { name: "L", code: 3 },
  //   { name: "XL", code: 4 },
  //   { name: "XXL", code: 5 },
  //   { name: "XXXL", code: 6 },
  // ];

  // const roles = [
  //   { name: "Asesor Ventas", code: 0 },
  //   { name: "Freelance", code: 1 },
  //   { name: "Coordinador", code: 2 },
  // ];

  const onLinesChange = (e) => {
    let selectedLines = [
      ...(formik.values.lines === "" ? [] : formik.values.lines),
    ];
    if (e.checked) selectedLines.push(e.value);
    else selectedLines.splice(selectedLines.indexOf(e.value), 1);
    formik.setFieldValue("lines", selectedLines);
  };

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  let maxDate = new Date();
  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <div className="grid grid-nogutter">
            <div className="col-12 md:col-10 md:col-offset-1 contentForm">
              <h2>Actualización de datos de usuario</h2>
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="grid">
                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Aliado: </p>
                    <div className="aliado">
                      {alliesData
                        ? alliesData.map((ally) => {
                            return <p key={ally.alliedCode}>{ally.allyName}</p>;
                          })
                        : ""}
                    </div>
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Sucursal a la que represento: *</p>

                    <div className="aliado">
                      {branchesData
                        ? branchesData.map((su) => {
                            return (
                              <p className="profileDes" key={su.branchCode}>
                                {su.branchCode} - {su.nameBranch}
                              </p>
                            );
                          })
                        : ""}
                    </div>
                    <br />
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Soy un: *</p>
                    <div className="aliado">
                      <p>{dataUser.role.name}</p>
                    </div>
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Cédula: </p>
                    <div className="aliado">
                      <p>{dataUser.dni}</p>
                    </div>
                  </div>
                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Nombres: *</p>
                    <InputText
                      id="names"
                      name="names"
                      value={formik.values.names}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("names"),
                        },
                        "textForm"
                      )}
                    />
                    {getFormErrorMessage("names")}
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Apellidos: *</p>
                    <InputText
                      id="surnames"
                      name="surnames"
                      value={formik.values.surnames}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("surnames"),
                        },
                        "textForm"
                      )}
                    />
                    {getFormErrorMessage("surnames")}
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Celular: *</p>
                    <InputMask
                      id="mobilePhone"
                      name="mobilePhone"
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                      value={formik.values.mobilePhone}
                      onChange={(e) =>
                        formik.setFieldValue("mobilePhone", e.value)
                      }
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("mobilePhone"),
                        },
                        "textForm"
                      )}
                      type="tel"
                    ></InputMask>
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Otro teléfono: </p>
                    <InputMask
                      id="otherPhone"
                      name="otherPhone"
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                      value={formik.values.otherPhone}
                      onChange={(e) =>
                        formik.setFieldValue("otherPhone", e.value)
                      }
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("otherPhone"),
                        },
                        "textForm"
                      )}
                      type="tel"
                    ></InputMask>
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Correo electrónico: *</p>
                    <InputText
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("email"),
                        },
                        "textForm"
                      )}
                    />

                    {getFormErrorMessage("email")}
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Dirección de la sucursal: *</p>
                    <InputText
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("address"),
                        },
                        "textForm"
                      )}
                    />
                    {getFormErrorMessage("address")}
                  </div>

                  <PDC
                    submitted={submitted}
                    formik={formik}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                  />

                  <div className="col-12 md:col-6 sinPT">
                    <p className="pForm">Fecha de cumpleaños: </p>
                    <span className="p-float-label p-input-icon-right textForm">
                      <i className="textFormIcon pi pi-calendar" />
                      <Calendar
                        id="dateBirth"
                        name="dateBirth"
                        value={formik.values.dateBirth}
                        onChange={formik.handleChange}
                        disabled={submitted}
                        dateFormat="dd/mm/yy"
                        // minDate={minDate}
                        maxDate={maxDate}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid("dateBirth"),
                          },
                          "textForm"
                        )}
                      />
                    </span>
                    {getFormErrorMessage("dateBirth")}
                  </div>

                  <div className="col-12 sinPT">
                    <p className="pForm">
                      Selecciona las líneas que comercializa tu punto de venta:
                    </p>
                  </div>

                  <div className="col-12 md:col-6 sinPT">
                    {linesData.map((lin) => {
                      return (
                        <div className="field-checkbox" key={lin.id}>
                          <Checkbox
                            inputId={lin.id}
                            name={lin.desc}
                            value={lin}
                            onChange={onLinesChange}
                            checked={formik.values.lines?.some(
                              (item) => item.id === lin.id
                            )}
                            disabled={submitted}
                          />
                          <label htmlFor={lin.desc}>{lin.desc}</label>
                        </div>
                      );
                    })}
                  </div>

                  <div className="col-12 sinPT">
                    <p className="pForm">
                      Cuéntanos porque en tu sucursal se consideran unos Titanes
                      Brilla:
                    </p>
                    <InputTextarea
                      value={formik.values.history}
                      id="history"
                      name="history"
                      onChange={formik.handleChange}
                      rows={5}
                      cols={30}
                      autoResize
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("history"),
                        },
                        "textForm"
                      )}
                      disabled={submitted}
                    />
                    {getFormErrorMessage("history")}
                  </div>

                  <div className="col-12 md:col-4 md:col-offset-4">
                    <Button
                      className="p-button-brilla"
                      disabled={submitted}
                      type="submit"
                    >
                      Actualizar
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default EditUser;
