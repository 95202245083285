import React, { useEffect, useState } from "react";
//PRIMEREACT
import { Carousel } from "primereact/carousel";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import Training from "./Training";
import User from "./templates/User";
import Insignias from "./templates/Insignias";
import InsigniActual from "./templates/InsigniActual";
// import MyImageGallery from "./Gallery/MyImageGallery";
//ESTILOS
import "./Winners.css";
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Winners() {
  const { dataUser, configurations } = useUsuario();
  const [insigniasData, setInsigniasData] = useState([]);
  const [dataUserCompl, setDataUserCompl] = useState([]);
  const [dataWinners, setDataWinners] = useState([]);

  //Obterner los datos de los usuarios ganadores completos para poder traer el aliado, sucursal,
  // foto y el historial de sus insignias
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener los datos de los usuarios ganadores de insignias
        const dataUsersW = await fglobales.getIdWinners("winnersInsignias");
        const dataInsignias = await fglobales.getCollectionData("insignias");
        const dataWinnersAct = await fglobales.getDatosABC(
          "winnersInsignias",
          "status",
          true
        );

        if (dataUsersW.length > 0) {
          // Obtener los datos completos de los usuarios ganadores
          const dataUsersCom = await fglobales.getDatosArray(
            "usersClients",
            "userId",
            dataUsersW
          );

          setDataUserCompl(dataUsersCom);
          setInsigniasData(dataInsignias);
          setDataWinners(dataWinnersAct[0]);
        } else {
          console.log("No se encontraron usuarios ganadores de insignias.");
        }
      } catch (error) {
        // Manejar errores de manera adecuada
        console.error("Error al obtener datos de usuarios ganadores:", error);
      }
    };

    fetchData();
  }, []);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (winner) => {
    return (
      <div className='grid grid-nogutter md:p-3'>
        <div className='col-12 md:col-5 centrar'>
          <img
            src={winner.userImg}
            onError={(e) =>
              (e.target.src =
                "https://firebasestorage.googleapis.com/v0/b/incentivos-brilla.appspot.com/o/plantilla%2Ftitanes.png?alt=media&token=631aa6f7-16ff-4fd0-a7a4-d0f132e5d076")
            }
            alt={winner.names + winner.surnames}
            className='product-image'
          />
        </div>

        <div className='col-12 md:col-7 pl-1 md:pl-4'>
          <User userData={winner} />
          <br />
          <h3 className='text-center md:text-left mt-0 mb-3'>
            Insignia ganada:
          </h3>
          <div className='grid'>
            <InsigniActual
              dataWinners={dataWinners}
              insigniasData={insigniasData}
            />
          </div>
          <br />
          <h3 className='text-center md:text-left mt-0 mb-3'>
            Insignias acumuladas:
          </h3>
          <div className='grid'>
            <Insignias
              insignias={winner.insignias}
              insigniasData={insigniasData}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='grid grid-nogutter container-principal container-winner'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1>Sucursales Ganadoras</h1>
          <p className='text-center pb-5'>
            Cada mes y cada reto ha venido cargado de pruebas que han llevado a
            estos Titanes a superarse a sí mismos y a adquirir las insignias que
            simbolizan su esfuerzo y perseverancia todos los meses. ¡Ustedes
            pueden ser parte de nuestros grandes Titanes! Continúen
            perseverantes en cada reto y demuestren que cumplen con las
            cualidades de los verdaderos <b>Titanes del Olimpo</b>.
          </p>

          <div className='grid'>
            <div className='col-12 contentIng'>
              {dataUserCompl.length > 0 && insigniasData.length > 0 ? (
                <>
                  <Carousel
                    value={dataUserCompl}
                    numVisible={1}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    circular
                    autoplayInterval={10000}
                    itemTemplate={productTemplate}
                    // showItemNavigators
                    showIndicators
                  />
                </>
              ) : (
                <LoaderSimple />
              )}
            </div>
          </div>
        </div>

        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1>Calendario de capacitaciones</h1>
          <p className='text-4xl md:text-5xl text-center'>
            <b>¡Queremos que crezcan cada día más, Titanes!</b>
          </p>
          <p className='text-center pb-5'>
            Aquí podrán conocer todas las capacitaciones que tenemos para
            ustedes, éstas los ayudarán a alcanzar más OLIMPOS mes a mes.
            Ingresa ahora y conoce información que te permitirá obtener EL PODER
            TITÁN.
          </p>

          <div className='grid contentForm'>
            {/* <div className="col-12 md:col-4">
              <MyImageGallery />
            </div> */}
            <div className='col-12'>
              <Training
                dataUser={dataUser}
                configurations={configurations}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Winners;
