import {
  query,
  getDocs,
  collection,
  orderBy,
  runTransaction,
  serverTimestamp,
  increment,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment-timezone";

const fInventory = {};

fInventory.getInventory = async () => {
  const q = query(collection(db, "inventory"), orderBy("order"));

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fInventory.addOrder = async (data, items, dataUser, totalCost) => {
  return new Promise(async (resolve, reject) => {
    const showButton = fInventory.shouldShowButton();
    if (showButton) {
      const stimpyDocRef = doc(db, "config", "stimpy");
      const userDocRef = doc(db, "usersClients", dataUser.userId);
      const orderDocRef = doc(collection(db, "order"));

      await runTransaction(db, async (transaction) => {
        const getUser = await transaction.get(userDocRef);
        const getStimpy = await transaction.get(stimpyDocRef);

        if (!getUser.exists()) {
          reject({
            icon: "pi-times-circle",
            colorIcon: "#FF0000",
            headerMessage: "El usuario no existe",
            message:
              "Error inesperado; póngase en contacto con soporte técnico",
            error: 0,
          });
          throw new Error("El usuario no existe");
        }

        if (totalCost > getUser.data().olimpos) {
          reject({
            icon: "pi-times-circle",
            colorIcon: "#FF0000",
            headerMessage: "Olimpos insuficientes",
            message: "No tienes suficientes puntos",
            error: 1,
          });
          throw new Error("Olimpos insuficientes");
        }

        const inventoryData = [];

        for (const item of items) {
          const inventoryDocRef = doc(db, "inventory", item.id);
          const getInventory = await transaction.get(inventoryDocRef);

          if (
            !getInventory.exists() ||
            getInventory.data().quantity < item.quantity
          ) {
            reject({
              icon: "pi-times-circle",
              colorIcon: "#FF0000",
              headerMessage:
                "Hubo un problema con la existencia de los productos.",
              message:
                "Error inesperado; póngase en contacto con soporte técnico",
              error: 2,
            });
            throw new Error(
              "Hubo un problema con la existencia de los productos."
            );
          }

          inventoryData.push({ inventoryDocRef, quantity: item.quantity });
        }

        for (const { inventoryDocRef, quantity } of inventoryData) {
          transaction.update(inventoryDocRef, {
            quantity: increment(-quantity),
          });
        }

        transaction.update(userDocRef, { olimpos: increment(-totalCost) });

        transaction.set(orderDocRef, {
          ...data,
          timestamp: serverTimestamp(),
          creatorUser: dataUser,
          items: items,
          remainingOlimpos: getUser.data().olimpos - totalCost,
          numberOrder: getStimpy.data().pipe,
          totalCost: totalCost,
        });

        transaction.update(stimpyDocRef, {
          ren: increment(-totalCost),
          noggin: increment(totalCost),
          pipe: increment(1),
        });
      })
        .then(() => {
          resolve({
            icon: "pi-check-circle",
            colorIcon: "green-500",
            headerMessage: <div>"¡Felicidades! ya redimiste tus Olimpos."</div>,
            message: "En tu email tendrás un recibo de esta transacción.",
            error: 3,
          });
        })
        .catch((err) => {
          // Aquí puedes manejar la excepción lanzada anteriormente.
          console.error(err);
        });
    }
  });
};

fInventory.shouldShowButton = () => {
  const colombiaCurrentDate = moment.tz("America/Bogota");
  const currentDate = colombiaCurrentDate.date();

  // Comprobamos si estamos entre el 18 y el 28 del mes actual.
  if (currentDate >= 18 && currentDate <= 28) {
    return true;
  } else {
    return false;
  }
};

// fInventory.OLDshouldShowButton = () => {
//   const colombiaCurrentDate = moment.tz("America/Bogota");
//   const dayOfMonth = colombiaCurrentDate.date();
//   const isValidDay = dayOfMonth >= 28 || dayOfMonth <= 7;
//   return isValidDay;
// };

export default fInventory;
