import React from "react";
import "./Maintenance.css";
import mantenimientoImg from "../../assets/images/mantenimiento.png";

function Maintenance() {
  return (
    <>
      <div className="mantenimientoC">
        <img src={mantenimientoImg} alt="Mantenimiento" />
      </div>
    </>
  );
}

export default Maintenance;
