import React, { useState, useEffect, useMemo, useContext } from "react";
// Firebase
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";
//Services
import fglobales from "../services/fglobales";

const UsuarioContext = React.createContext();

export function UsuarioProvider(props) {
  const [dataUser, setDataUser] = useState(null);
  const [roles, setRoles] = useState(null);
  const [configurations, setConfigurations] = useState(null);
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, async (user) => {
      if (user) {
        user.getIdTokenResult().then(async (idTokenResult) => {
          let getUser = await fglobales.getDocId("usersClients", user.uid);
          if (getUser.exists) {
            let configurations = await fglobales.getDocId(
              "config",
              "configurations"
            );
            let userData = getUser.data();

            // Solo se trae una sucursal
            // Si se cambia, se ha de cambiar la modal send del carrito de compras
            const docData = await fglobales.getDocId(
              "branches",
              userData.branches[0]
            );

            userData.dataBranch = docData.data();
            setDataUser({
              ...userData,
              fullName: `${userData.names} ${userData.surnames}`,
              userId: user.uid,
            });
            setUser(user);
            setRoles(
              idTokenResult.claims.roles ? idTokenResult.claims.roles : []
            );
            setConfigurations(configurations.data());
            setLoad(false);
          } else {
            setRoles(null);
            setUser(null);
            setLoad(false);
          }
        });
      } else {
        // console.log("Reiniciado");
        setDataUser(null);
        setUser(null);
        setLoad(true);
        setTimeout(() => {
          setLoad(false);
        }, 2000);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  const value = useMemo(() => {
    return {
      dataUser,
      roles,
      configurations,
      isLogged() {
        return !!user;
      },
      isLoadUser() {
        return !!load;
      },
      signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
      },
      logout() {
        return signOut(auth);
      },

      async resetDataUser() {
        console.log("entro al reset");
        let getUser = await fglobales.getDocId("usersClients", user.uid);
        let userData = getUser.data();
        setDataUser({
          ...userData,
          fullName: `${userData.names} ${userData.surnames}`,
          userId: user.uid,
        });
      },
    };
  }, [dataUser, load, user, roles, configurations]);

  return (
    <>
      <UsuarioContext.Provider value={value} {...props} />
    </>
  );
}

export function useUsuario() {
  const context = useContext(UsuarioContext);
  if (!context) {
    throw new Error(
      "useUsuario debe estar dentro del proveedor UsuarioContext"
    );
  }
  return context;
}
