import React from "react";
//PRIME REACT
import { Button } from "primereact/button";

import { confirmDialog } from "primereact/confirmdialog";
// SERVICIOS
import fglobales from "../../services/fglobales";
import { toast } from "react-toastify";

function ActionButtons({ collectionText, rowData, setEditData }) {
  //Editar
  const handleEditData = (data) => {
    setEditData({ ...data });
  };

  // ELIMINACION MENSAJE
  const confirm = (rowData) => {
    confirmDialog({
      message: "¿Estas seguro que deseas continuar?",
      header: "Eliminar",
      icon: "pi pi pi-times",
      accept: () => accept(rowData),
      reject: () => reject(),
    });
  };

  //ELIMINACION PROCEDIMIENTO
  const accept = async (rowData) => {
    const deleteDoc = await fglobales.deleteDoc(collectionText, rowData.id);

    if (deleteDoc) {
      toast.success("Eliminado satisfactoriamente");
    } else {
      toast.error("Eliminación fallida");
    }
  };

  const reject = () => {
    console.log("Error");
  };

  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-5">
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success"
            onClick={() => handleEditData(rowData)}
          />
        </div>
        <div className="col-12 md:col-5">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger"
            onClick={() => confirm(rowData)}
          />
        </div>
      </div>
    </>
  );
}

export default ActionButtons;
