import React from "react";

function Insignias({ insignias, insigniasData }) {
  return insignias.map((insignia) => {
    let insigniaData = insigniasData.find((val) => val.id === insignia.id);
    return (
      <div className="col-4 md:col-3 p-0" key={insigniaData.id}>
        <img src={insigniaData.imgUrl} alt={insigniaData.name} />
      </div>
    );
  });
}

export default Insignias;
