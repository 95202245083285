import React, { useState, useEffect } from "react";
//PRIMEREACT
import { Button } from "primereact/button";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import InsigniasStatus from "./InsigniasStatus";
import InsigniasAcount from "./InsigniasAcount";
import InsigniasStructure from "./InsigniasStructure";
//ESTILOS
import "./Insignias.css";
//MEDIA
//FIREBASE
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
//ROUTER
import { useNavigate } from "react-router-dom";
//SERVICIOS
import fanalytics from "../../services/fanalytics";

function Insignias({ dataUser }) {
  const [dataInsignias, setDataInsignias] = useState(null);
  const [dataInsigniasTotales, setDataInsigniasTotales] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(db, "insignias"));
    const unlistenI = onSnapshot(q, (docs) => {
      let insigniasD = [];

      docs.forEach((doc) => {
        insigniasD.push({ ...doc.data(), id: doc.id });
      });

      let insActual = insigniasD.filter((data) => data.status === true);
      setDataInsignias(insActual[0]);
      setDataInsigniasTotales(insigniasD);
    });
    return () => {
      unlistenI();
    };
  }, []);

  return (
    <>
      <div className="col-12 md:col-10 md:col-offset-1 mt-4 contentForm">
        <div className="grid">
          <div className="col-12 md:col-10 md:col-offset-1">
            <h3 className="text-center">Kiosco de insignias</h3>
            <p className="textCampana">
              Cada uno de ustedes, <b>TITANES</b>, podrá acumular{" "}
              <b>30 OLIMPOS</b> al mes. Estos representan un valor y todo el
              esfuerzo acumulado por ustedes y para ustedes. ¡Recuerda que cada
              una de estas insignias coleccionadas te hará merecedor de un gran
              beneficio!
            </p>
          </div>

          <div className="col-12 md:col-10 md:col-offset-1 ">
            <p className="olimpos mt-0">
              Tienes: <b>{dataUser.olimpos} Olimpos</b> disponibles
            </p>
          </div>

          {dataInsignias ? (
            <InsigniasStatus dataInsignias={dataInsignias} />
          ) : (
            <LoaderSimple />
          )}

          <div className="col-12 md:col-10 md:col-offset-1 ">
            <h3 className="text-center">Insignias acumuladas</h3>

            {dataUser.insignias.length > 0 ? (
              <InsigniasAcount
                dataUser={dataUser}
                LoaderSimple={LoaderSimple}
                dataInsigniasTotales={dataInsigniasTotales}
              />
            ) : (
              <InsigniasStructure />
            )}
          </div>

          <div className="col-12 md:col-6 md:col-offset-3">
            <br />
            <br />
            <Button
              className="p-button-brilla"
              type="submit"
              onClick={() => {
                navigate(`/tiendaolimpos`);
                fanalytics.logEvents(
                  "olimpos_click",
                  "button",
                  "olimpos_redención"
                );
              }}
            >
              Redime aquí tus Olimpos por beneficios
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Insignias;
