import React from "react";
//PRIMEREACT
import { Image } from "primereact/image";
import { Button } from "primereact/button";
//COMPONENTES
//ESTILOS
import "./Portal.css";
//MEDIA
//FIREBASE

function Video({ dataTutorial, openModal }) {
  return (
    <>
      {dataTutorial.length !== 0 ? (
        <div className="grid py-3 separd">
          <div className="col-12 md:col-3 visiblemd consursoCon">
            <Image src={dataTutorial.imgVideo} alt="Imagen" />
          </div>
          <div className="col-12 md:col-9 pl-5 pr-5 pb-4">
            <div className="col-12 visiblesm">
              <Image src={dataTutorial.imgVideo} alt="Imagen" />
            </div>
            <h3 className="m-0 text-center md:text-left uppercase">
              {dataTutorial.name}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: dataTutorial.desc,
              }}
            ></div>
            <div className="text-left">
              <Button
                className="p-button-video"
                // disabled={submitted}
                type="submit"
                onClick={() => {
                  openModal(dataTutorial.urlVideo, dataTutorial.name);
                }}
              >
                Ver video
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12">
          <h3>No existen tutoriales que apliquen a este distrito</h3>
        </div>
      )}
    </>
  );
}

export default Video;
