import React, { useEffect, useState } from "react";
// PRIMEREACT
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
// PRIMEREACT
// SERVICIOS
import fglobales from "../../services/fglobales";
// SERVICIOS

function PDC({ submitted, formik, isFormFieldValid, getFormErrorMessage }) {
  const [departments, setDepartments] = useState([]);
  const [loadS, setLoadS] = useState(true);
  const [municipalities, setMunicipalities] = useState([]);
  const [loadM, setLoadM] = useState(true);

  useEffect(() => {
    async function getDepartments() {
      setLoadS(true);
      let data = await fglobales.getDepartments(formik.values.country.id);
      setDepartments(data);
      setLoadS(false);
    }
    if (formik.values.country.id) {
      getDepartments();
    }
  }, [formik.values?.country.id]);

  useEffect(() => {
    async function getDepartments() {
      setLoadM(true);
      let data = await fglobales.getMunicipalities(
        formik.values.country.id,
        formik.values.department.id
      );
      setMunicipalities(data);
      setLoadM(false);
    }
    if (formik.values.country?.id && formik.values.department?.id) {
      getDepartments();
    }
  }, [formik.values?.country.id, formik.values?.department.id]);

  const getMunicipalities = async (department) => {
    setLoadM(true);
    let data = await fglobales.getMunicipalities(
      formik.values.country.id,
      department
    );
    setMunicipalities(data);
    setLoadM(false);
  };

  return (
    <>
      <div className="col-12 md:col-6 sinPT">
        <p className="pForm">Departamento: *</p>
        <Dropdown
          optionLabel="nombre"
          options={departments}
          id="department"
          name="department"
          value={formik.values.department}
          onChange={(e) => {
            getMunicipalities(e.value.id);
            formik.setFieldValue("department", e.value);
            formik.setFieldValue("municipality", "");
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("department"),
            },
            "textForm"
          )}
          disabled={submitted || loadS}
        />
        {getFormErrorMessage("department")}
      </div>
      <div className="col-12 md:col-6 sinPT">
        <p className="pForm">Ciudad/Municipio: *</p>
        <Dropdown
          optionLabel="municipality"
          options={municipalities}
          id="municipality"
          name="municipality"
          value={formik.values.municipality}
          onChange={(e) => {
            formik.setFieldValue("municipality", e.value);
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("municipality"),
            },
            "textForm"
          )}
          disabled={submitted || loadM}
          // placeholder="Municipio*"
        />
        {getFormErrorMessage("municipality")}
      </div>
    </>
  );
}

export default PDC;
