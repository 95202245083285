import React from "react";
import "../Register/Register.css";
import { Button } from "primereact/button";
//ROUTER
import { useNavigate } from "react-router-dom";

function UpdateUser() {
  let navigate = useNavigate();

  const volverActualizar = () => {
    localStorage.setItem("editUser", 0);
    navigate(`/perfilusuario`);
  };

  const volverPerfil = () => {
    navigate(`/perfil`);
  };

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <div className="grid grid-nogutter">
            <div className="col-12 md:col-10 md:col-offset-1 contentForm">
              <h2>La actualización de datos fue exitosa.</h2>

              <div className="grid">
                <div className="col-6">
                  <Button
                    className="p-button-brilla"
                    type="submit"
                    onClick={() => {
                      volverActualizar();
                    }}
                  >
                    Actualizar
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    className="p-button-brilla"
                    type="submit"
                    onClick={() => {
                      volverPerfil();
                    }}
                  >
                    Mi Sucursal
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateUser;
