import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// VIDEO
// import ReactPlayer from "react-player";

export default function ModalSurveys({
  showMessage,
  actionHidden,
  surveysData,
  updateSurveysId,
}) {
  const dialogFooter = (
    <Button
      label='Cerrar'
      className='p-button-brilla'
      autoFocus
      onClick={() => {
        updateSurveysId();
        actionHidden();
      }}
    />
  );

  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position='center'
      footer={dialogFooter}
      showHeader
      style={{ width: "60%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={surveysData ? surveysData.name : ""}>
      <div className='grid'>
        <div className='col-12'>
          <div className='modalImg'>
            <img
              src={surveysData.img}
              alt={surveysData.name}
            />
          </div>

          {/* <div
            div
            className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              url={surveysData.videoUrl}
              width='100%'
              height='100%'
              controls={true}
              playing={showMessage}
              playsinline
              // onEnded={() => endVideo()}
              onError={(error) => console.log(error)}
            />
          </div> */}
        </div>
      </div>
    </Dialog>
  );
}
