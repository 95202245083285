import React, { useState } from "react";
//PRIMEREACT
import { TabView, TabPanel } from "primereact/tabview";
//COMPONENTES
import ModalDataInsignias from "./ModalDataInsignias";
//ESTILOS
import "./Insignias.css";
//MEDIA
import Bloqueada from "../../assets/images/insigniabloqueada.png";
//FIREBASE

function InsigniasAcount({ dataUser, LoaderSimple, dataInsigniasTotales }) {
  const [showMessage, setShowMessage] = useState(false);
  const [showInsignia, setShowInsignia] = useState("");

  let semestres = [1, 2, 3, 4]; // Numero de semestres
  let nIS = [1, 2, 3, 4, 5, 6]; //Numero de insignias mensuales
  let nIT = [1, 2]; //Numero de insignias Trimestral
  let nIse = [1]; //Numero de insignias semestrales

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  const openModal = (dataIns) => {
    setShowInsignia(dataIns);
    setShowMessage(true);
  };

  return (
    <>
      {dataInsigniasTotales ? (
        <div className="contenedorIng">
          <div>
            <TabView>
              {semestres.map((semestre) => {
                return (
                  <TabPanel header={`Ciclo ${semestre}`} key={semestre}>
                    <div className="grid visiblemd">
                      <div className="col-4">
                        <p className="tAcumulada">Mensuales</p>
                      </div>
                      <div className="col-3">
                        <p className="tAcumulada">Trimestrales</p>
                      </div>
                      <div className="col-5">
                        <p className="tAcumulada">Semestrales</p>
                      </div>
                    </div>

                    <div className="grid padre-flex userIng">
                      {/* Mensual */}
                      <div className="col-12 md:col-4">
                        <div className="grid visiblesm">
                          <div className="col-12">
                            <p className="tAcumulada">Mensuales</p>
                          </div>
                        </div>
                        <div className="grid">
                          <div className="col-12">
                            <div className="grid">
                              {nIS.map((nInsignia) => {
                                let insignia = dataUser.insignias.find(
                                  (val) =>
                                    val.order === nInsignia &&
                                    val.semester === semestre &&
                                    val.type === 1
                                );
                                if (insignia) {
                                  let insigniaData = dataInsigniasTotales.find(
                                    (val) => val.id === insignia.id
                                  );
                                  return (
                                    <div
                                      className="col-6 md:col-6"
                                      key={`${nInsignia}`}
                                    >
                                      <img
                                        src={insigniaData.imgUrl}
                                        alt="Insignias"
                                        onClick={() => {
                                          openModal(insigniaData);
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="col-6 md:col-6"
                                      key={`${nInsignia}`}
                                    >
                                      <img src={Bloqueada} alt="Insignias" />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Trimestral */}
                      <div className="col-12 md:col-3 borderIng">
                        <div className="grid visiblesm">
                          <div className="col-12">
                            <p className="tAcumulada">Trimestrales</p>
                          </div>
                        </div>
                        <div className="grid">
                          <div className="col-12">
                            <div className="grid">
                              {nIT.map((nInsignia) => {
                                let insignia = dataUser.insignias.find(
                                  (val) =>
                                    val.order === nInsignia &&
                                    val.semester === semestre &&
                                    val.type === 3
                                );
                                if (insignia) {
                                  let insigniaData = dataInsigniasTotales.find(
                                    (val) => val.id === insignia.id
                                  );
                                  return (
                                    <div
                                      className="col-6 md:col-12"
                                      key={`${nInsignia}`}
                                    >
                                      <img
                                        src={insigniaData.imgUrl}
                                        alt="Insignias"
                                        onClick={() => {
                                          openModal(insigniaData);
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="col-6 md:col-12"
                                      key={`${nInsignia}`}
                                    >
                                      <img src={Bloqueada} alt="Insignias" />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Semestrales */}
                      <div className="col-12 md:col-5">
                        <div className="grid visiblesm">
                          <div className="col-12">
                            <p className="tAcumulada">Semestrales</p>
                          </div>
                        </div>
                        <div className="grid">
                          <div className="col-12">
                            <div className="grid">
                              {nIse.map((nInsignia) => {
                                let insignia = dataUser.insignias.find(
                                  (val) =>
                                    val.order === nInsignia &&
                                    val.semester === semestre &&
                                    val.type === 6
                                );
                                if (insignia) {
                                  let insigniaData = dataInsigniasTotales.find(
                                    (val) => val.id === insignia.id
                                  );
                                  return (
                                    <div
                                      className="col-12"
                                      key={`${nInsignia}`}
                                    >
                                      <img
                                        src={insigniaData.imgUrl}
                                        alt="Insignias"
                                        onClick={() => {
                                          openModal(insigniaData);
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="col-12"
                                      key={`${nInsignia}`}
                                    >
                                      <img src={Bloqueada} alt="Insignias" />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                );
              })}
            </TabView>
          </div>
        </div>
      ) : (
        <LoaderSimple />
      )}

      <ModalDataInsignias
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        showInsignia={showInsignia}
      />
    </>
  );
}

export default InsigniasAcount;
