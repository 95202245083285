import React, { useEffect, useState } from "react";
//COMPONENTES
import LoaderSimple from "../../LoaderSimple";
//SERVICIOS
import fglobales from "../../../services/fglobales";

function User({ userData }) {
  const [alliesData, setAlliesData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);

  useEffect(() => {
    const miFuncionAsincrona = async () => {
      try {
        const docAlly = await fglobales.getDatosABC(
          "allies",
          "alliedCode",
          userData.allies[0]
        );

        const docBran = await fglobales.getDatosAB(
          "branches",
          "branchCode",
          userData.branches[0]
        );

        setAlliesData(docAlly);
        setBranchesData(docBran);
      } catch (error) {
        console.log(error);
      }
    };

    miFuncionAsincrona();
  }, [userData]);

  return (
    <>
      {alliesData.length > 0 && branchesData.length > 0 ? (
        <>
          <h3 className="text-center md:text-left mt-0 mb-0">
            Aliado ganador:
          </h3>
          <p className="profileDes text-center md:text-left mt-0 mb-0">
            {alliesData[0].allyName}
          </p>
          <br />

          <h3 className="text-center md:text-left mt-0 mb-0">
            Sucursal ganador:
          </h3>
          <p className="profileDes text-center md:text-left mt-0 mb-0">
            {branchesData[0].nameBranch}
          </p>
        </>
      ) : (
        <>
          <LoaderSimple />
        </>
      )}
    </>
  );
}

export default User;
