import React, { useState, useEffect } from "react";
//PRIMEREACT
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import ContestItems from "./ContestItems";
//ESTILOS
import "./Campaign.css";
//FIREBASE
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
//SERVICIOS
import moment from "moment";
import "moment/locale/es";

function Contest({ selectCampanas, dataUser }) {
  const [contestCam, setContestCam] = useState(null);

  useEffect(() => {
    const q = collection(doc(db, "campaign", selectCampanas.id), "contest");

    const unlistenI = onSnapshot(q, (docs) => {
      let constestCampana = [];

      docs.forEach((doc) => {
        let docData = doc.data();

        if (docData.status === true) {
          docData.dateInicio = docData.dateInit
            ? moment(doc.data().dateInit.toDate()).format("DD/MM/YYYY")
            : "---";

          docData.dateFinal = docData.dateEnd
            ? moment(doc.data().dateEnd.toDate()).format("DD/MM/YYYY")
            : "---";

          constestCampana.push({ ...docData, id: doc.id });
        }
      });
      setContestCam(constestCampana);
    });
    return () => {
      unlistenI();
    };
  }, [selectCampanas.id]);

  return (
    <>
      {contestCam ? (
        contestCam.map((con, k) => {
          return (
            <div key={k}>
              <ContestItems
                contestData={con}
                dataUser={dataUser}
              />
            </div>
          );
        })
      ) : (
        <LoaderSimple />
      )}
    </>
  );
}

export default Contest;
