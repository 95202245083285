import React, { useState, useEffect } from "react";
//COMPONENTES
import ModalComments from "./ModalComments";
import LoaderSimple from "../LoaderSimple";
//ESTILOS
import "./Campaign.css";
//FIREBASE
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  collection,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
//MEDIA
import Avatar from "../../assets/images/avatar.png";
//SERVICIOS
import moment from "moment";
import "moment/locale/es";

function UserComments({ userComent, dataUser }) {
  const [submitted, setSubmitted] = useState(false);
  const [selectLikes, setSelectLikes] = useState(
    userComent ? userComent.likeComment : false
  );
  const [subCommentsUser, setSubCommentsUser] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showComments, setShowComments] = useState("");

  useEffect(() => {
    const q = query(
      collection(
        db,
        "campaign",
        userComent.idCampaign,
        "comments",
        userComent.id,
        "subComments"
      ),
      orderBy("timestamp", "desc")
    );

    const unlistenI = onSnapshot(q, (docs) => {
      let subComments = [];

      docs.forEach((doc) => {
        subComments.push({ ...doc.data(), id: doc.id });
      });
      setSubCommentsUser(subComments);
    });
    return () => {
      unlistenI();
    };
  }, [userComent]);

  const likedComments = async () => {
    const q = doc(db, "campaign", userComent.idCampaign);
    const qSubC = doc(q, "comments", userComent.id);

    try {
      if (!userComent.likes.includes(dataUser.userId)) {
        setSelectLikes(true);
        await updateDoc(qSubC, {
          likes: arrayUnion(dataUser.userId),
          likeComment: true,
          likeCount: increment(1),
        });
        setSubmitted(true);
      } else {
        setSelectLikes(false);
        await updateDoc(qSubC, {
          likes: arrayRemove(dataUser.userId),
          likeComment: false,
          likeCount: increment(-1),
        });
        setSubmitted(true);
      }
    } catch (e) {
      console.log("Transacción fallida: ", e);
    }
    setTimeout(() => {
      setSubmitted(false);
    }, 4000);
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  const openModal = (coment) => {
    setShowComments(coment);
    setShowMessage(true);
  };

  return (
    <>
      <div className='grid'>
        <div className='col-12 mb-1'>
          <div className='grid'>
            <div className='col-3 md:col-1 avatarImg'>
              {userComent.avatar &&
              userComent.avatar !==
                "https://firebasestorage.googleapis.com/v0/b/incentivos-brilla.appspot.com/o/plantilla%2Favatar.png?alt=media&token=b72b9049-e4c5-4b52-8fc3-56fc0e377de5" ? (
                <img
                  src={userComent.avatar}
                  alt='Avatar'
                />
              ) : (
                <img
                  src={Avatar}
                  alt='Avatar'
                />
              )}
            </div>
            <div className='col-9 md:col-11 pb-0'>
              <div className='grid'>
                <div className='col-12 p-0 pl-1'>
                  <p className='tcampanas m-0'>{userComent.nameUser}</p>
                </div>
                <div className='col-12 pl-1 overflow-hidden'>
                  <p className='subtcampanas mt-0 mb-0'>
                    {userComent.comments}
                  </p>
                </div>
                <div className='col-12'>
                  <p className='dateComment'>
                    {userComent.timestamp
                      ? moment(userComent.timestamp.toDate()).format(
                          "DD/MM/YYYY hh:mm:ss A"
                        )
                      : ""}
                  </p>
                </div>

                <div className='col-12 pl-1 pt-0'>
                  <div className='grid'>
                    <div className='col-12'>
                      <ul className='listComment'>
                        <li>
                          <button
                            className='p-button-responder'
                            onClick={() => {
                              openModal(userComent);
                            }}>
                            Responder
                          </button>
                        </li>
                        <li className='puntoS'>&#8226;</li>
                        <li>
                          <button
                            className={
                              selectLikes ? "like-button liked" : "like-button"
                            }
                            onClick={() => {
                              likedComments(selectLikes);
                            }}
                            disabled={submitted}>
                            <span className='like-icon'>
                              <div className='heart-animation-1'></div>
                              <div className='heart-animation-2'></div>
                            </span>
                            <span className='numLike'>
                              {" "}
                              {!userComent || userComent.likes.length <= 0
                                ? ""
                                : userComent.likes.length > 99
                                ? "99+"
                                : userComent.likes.length}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/*Comentarios Internos*/}

                <div className='col-12 contentResp'>
                  {subCommentsUser ? (
                    subCommentsUser
                      .filter((data) => data.commentsId === userComent.id)
                      .map((subC, index) => {
                        return (
                          <div
                            className='grid mb-3'
                            key={`${index}${subC.id}`}>
                            <div className='col-3 md:col-1 avatarImg'>
                              {subC.avatar &&
                              subC.avatar !==
                                "https://firebasestorage.googleapis.com/v0/b/incentivos-brilla.appspot.com/o/plantilla%2Favatar.png?alt=media&token=b72b9049-e4c5-4b52-8fc3-56fc0e377de5" ? (
                                <img
                                  src={subC.avatar}
                                  alt='Avatar'
                                />
                              ) : (
                                <img
                                  src={Avatar}
                                  alt='Avatar'
                                />
                              )}
                            </div>
                            <div className='col-9 md:col-11 pb-0'>
                              <div className='grid'>
                                <div className='col-12 p-0 pl-1'>
                                  <p className='tcampanas m-0'>
                                    {subC.nameUser}
                                  </p>
                                </div>
                                <div className='col-12 pl-1 overflow-hidden'>
                                  <p className='subtcampanas mt-0 mb-0'>
                                    {subC.subComments}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <LoaderSimple />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalComments
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        showComments={showComments}
        dataUser={dataUser}
      />
    </>
  );
}

export default UserComments;
