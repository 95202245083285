import React, { useState } from "react";
//COMPONENTES
import Register from "./Register";
import ValidationDNI from "./ValidationDNI";
//RUTAS
//ESTILOS
import "./Register.css";
//MEDIA
import Mnemosine from "../../assets/images/insignias/Mnemosine.png";
import Theia from "../../assets/images/insignias/Theia.png";
import Rea from "../../assets/images/insignias/Rea.png";
import Themis from "../../assets/images/insignias/Themis.png";
import Tetis from "../../assets/images/insignias/Tetis.png";
import Methis from "../../assets/images/insignias/Methis.png";

function Information({ configurations }) {
  const [dniExist, setDniExist] = useState(false);
  const [dataUserPre, setDataUserPre] = useState(null);
  const [alliesData, setAlliesData] = useState([]);
  const [branchesData, setDranchesData] = useState([]);
  const [linesData, setLinesData] = useState([]);

  return (
    <>
      <div className='grid grid-nogutter container-principal'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <div className='grid grid-nogutter'>
            <div className='col-12 md:col-10 md:col-offset-1'>
              <h1>
                ¿Quieren ser parte de <br /> Titanes Brilla?
              </h1>
              <p className='text-3xl md:text-5xl text-center'>
                <b>¡Esperamos que estén listos!</b>
              </p>
              <p className='mt-0'>
                Tienen en sus manos el boleto de entrada a la emocionante tierra
                de los Titanes, un lugar en el que vivirán una aventura llena de
                retos, recompensas, galardones y muchas sorpresas más. Recuerda
                que ahora ustedes son los protagonistas en esta tierra, en la
                que tendrán que ganar retos y completar misiones que los
                llevarán a ganar <b>OLIMPOS</b>, que representan los retos
                acumulados y las insignias que coleccionan para redimir premios.
                <br />
                ¿Qué esperan para registrarse? Creemos que son verdaderos
                <b>TITANES</b> con las siguientes cualidades:
              </p>

              <div className='grid'>
                <div className='col-12 md:col-6'>
                  <ul className='p-0 mt-0 mb-0'>
                    <li>
                      <i className='pi pi-bolt'></i> Saben escuchar
                    </li>
                    <li>
                      <i className='pi pi-bolt'></i> Poseen dotes de
                      comunicación
                    </li>
                    <li>
                      <i className='pi pi-bolt'></i> Son seguros de sí mismos
                    </li>
                    <li>
                      <i className='pi pi-bolt'></i> Son honrados
                    </li>
                  </ul>
                </div>
                <div className='col-12 md:col-6'>
                  <ul className='p-0 mt-0'>
                    <li>
                      <i className='pi pi-bolt'></i> Son analíticos
                    </li>
                    <li>
                      <i className='pi pi-bolt'></i> Son creativos
                    </li>
                    <li>
                      <i className='pi pi-bolt'></i> Se mantienen siempre
                      motivados y con actitud
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='col-12 md:col-10 md:col-offset-1'>
              <h1>
                Si ganan insignias tendrán todo <br />
                lo que los verdaderos Titanes desean
              </h1>

              <div className='grid grid-nogutter'>
                <div className='col-12 md:col-10 md:col-offset-1'>
                  <div className='grid'>
                    <div className='col-4 md:col-2'>
                      <img
                        src={Mnemosine}
                        alt='Mnemosine'
                      />
                    </div>

                    <div className='col-4 md:col-2'>
                      <img
                        src={Theia}
                        alt='Theia'
                      />
                    </div>

                    <div className='col-4 md:col-2'>
                      <img
                        src={Rea}
                        alt='Rea'
                      />
                    </div>

                    <div className='col-4 md:col-2'>
                      <img
                        src={Themis}
                        alt='Themis'
                      />
                    </div>

                    <div className='col-4 md:col-2'>
                      <img
                        src={Tetis}
                        alt='Tetis'
                      />
                    </div>

                    <div className='col-4 md:col-2'>
                      <img
                        src={Methis}
                        alt='Methis'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Cada insignia es el resultado del esfuerzo realizado cada día
                para lograr nuevos retos. Por eso, al acumular <b>OLIMPOS</b> en
                el mes podrán recibir una insignia, ésta es la recompensa
                simbólica que se le otorgará a ustedes <b>TITANES</b> por haber
                cumplido los tres retos del mes, es decir, haber acumulado 30{" "}
                <b>OLIMPOS</b>.
              </p>

              {/* <p>
                Cada mes habrá una insignia diferente, al obtenerla podrán
                recibir beneficios, reconocimientos especiales y sorpresas.
              </p> */}
            </div>
            <div className='col-12 md:col-10 md:col-offset-1 contentForm'>
              <ValidationDNI
                setDniExist={setDniExist}
                dniExist={dniExist}
                setDataUserPre={setDataUserPre}
                setAlliesData={setAlliesData}
                setDranchesData={setDranchesData}
                setLinesData={setLinesData}
              />

              {dataUserPre ? (
                <Register
                  dniExist={dniExist}
                  dataUserPre={dataUserPre}
                  alliesData={alliesData}
                  branchesData={branchesData}
                  linesData={linesData}
                  configurations={configurations}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Information;
