import React from "react";
// PRIMEREACT
// import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
//MEDIA

export default function ModalItems({ showItem, actionHidden, item }) {
  return (
    <Dialog
      visible={showItem}
      onHide={() => actionHidden()}
      position="center"
      showHeader
      style={{ width: "50%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={<h3 className="m-0 text-center">Producto agregado al carrito</h3>}
    >
      <div className="grid grid-nogutter">
        <div className="col-12 p-4">
          <div className="grid p-2 md:p-5">
            <div className="col-12">
              <div className="grid movilItem">
                <div className="col-12 md:col-3 img-responsive">
                  <img src={item.img} alt={item.name} />
                </div>
                <div className="col-12 md:col-9 pl-4 pr-4 textMovil">
                  <div className="grid">
                    <div className="col-12">
                      <p className="nameOrder mt-0 mb-2">{item.name}</p>
                    </div>
                    <div className="col-12">
                      <p className="orderDesc m-0">{item.description}</p>
                    </div>
                    <div className="col-12">
                      <p className="olimposOrder m-0 visiblemd">
                        Valor: {item.cost} olimpos
                      </p>

                      <div className="grid visiblesm">
                        <div className="col-12 p-0">
                          <p className="olimposOrder m-0">Valor</p>
                        </div>
                        <div className="col-12 p-0">
                          <p className="olimposOrder m-0">
                            {item.cost} Olimpos
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <p className="subtcampanas resaltarC">
                        Recuerda que la entrega de este premio se realizará en
                        10 días hábiles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
