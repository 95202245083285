import React, { useState, useEffect } from "react";
//PRIMEREACT
import { Galleria } from "primereact/galleria";
//ESTILOS
import "./Banner.css";
//FIREBASE
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

function Banner({ bannerName }) {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "banners"),
      where("type", "==", bannerName),
      where("status", "==", true)
    );
    const unlistenI = onSnapshot(q, (docs) => {
      let bannersPage = [];

      docs.forEach((doc) => {
        bannersPage.push({ ...doc.data() });
      });

      // Ordenar el array en función del campo "order"
      bannersPage.sort((a, b) => b.order - a.order);

      setBanners(bannersPage);
    });
    return () => {
      unlistenI();
    };
  }, [bannerName]);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const itemTemplate = (item) => {
    return (
      <img
        src={item.imagenUrl}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt="Banner"
        style={{ width: "100%" }}
      />
    );
  };

  return (
    <>
      <div className="grid grid-nogutter">
        <div className="col-12 ">
          <Galleria
            value={banners}
            responsiveOptions={responsiveOptions}
            numVisible={1}
            style={{ maxWidth: "100%" }}
            circular
            autoPlay
            transitionInterval={3000}
            showItemNavigators
            showThumbnails={false}
            showItemNavigatorsOnHover={false}
            showIndicators
            item={itemTemplate}
          />
        </div>
      </div>
    </>
  );
}

export default Banner;
