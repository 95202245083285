import React, { useEffect, useState } from "react";
//PRIMEREACT
import { ProgressBar } from "primereact/progressbar";
//COMPONENTES
// import LoaderSimple from "../LoaderSimple";
import CountUp from "react-countup";
//ESTILOS
import "./Compliance.css";
//FIREBASE
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { db } from "../../firebase";
//SERVICES
import myformats from "../../services/myformats";
import moment from "moment";
import "moment/locale/es";

function Compliance({ dataUser, configurations }) {
  const [dataBranches, setDataBranches] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "branches"),
      where("branchCode", "==", dataUser.branches[0])
    );

    const unlistenI = onSnapshot(q, (docs) => {
      let branches = [];

      docs.forEach((doc) => {
        branches.push(doc.data());
      });

      setDataBranches(branches);
    });
    return () => {
      unlistenI();
    };
  }, [dataUser.branches]);

  return (
    <>
      <div className="col-12 md:col-10 md:col-offset-1 mt-4 contentForm">
        {dataBranches.map((branch) => {
          return (
            <div className="grid mt-3 md:mt-6" key={branch.branchCode}>
              <div className="col-12 md:col-10 md:col-offset-1">
                <h2 className="mt-0 text-center">
                  <b>
                    {branch.nameBranch} - {branch.branchCode}
                  </b>
                </h2>
              </div>
              <div className="col-12 md:col-10 md:col-offset-1">
                <div className="grid">
                  <div className="col-12">
                    <div className="grid">
                      <div className="col-12 md:col-6">
                        <p className="sucursalCum">
                          <b>Metas de ventas</b>
                        </p>
                        <p className="vMetas">
                          $
                          {branch.salesGoals > 0
                            ? myformats.formatDE(branch.salesGoals)
                            : 0}
                          {/* {myformats.formatDE(branch.salesGoals)} */}
                        </p>
                      </div>
                      <div className="col-12 md:col-6">
                        <p className="sucursalCum">
                          <b>
                            Cumplimiento a corte (ventas legalizadas) {" "}
                            {configurations.complianceDate
                              ? moment(
                                  configurations.complianceDate.toDate()
                                ).format("DD/MM/YYYY")
                              : ""}
                          </b>
                        </p>

                        <CountUp
                          start={0}
                          end={`${branch.branchCompliance}`}
                          delay={0}
                          duration={3}
                          separator="."
                          decimals={0}
                          decimal="."
                          prefix="$"
                        >
                          {({ countUpRef }) => (
                            <p className="vCumplientos" ref={countUpRef}></p>
                          )}
                        </CountUp>
                      </div>
                      <div className="col-12">
                        <br />
                        <div className="grid">
                          <div className="col-12 md:col-10 pb-0">
                            <p className="sucursalCum">
                              <b>
                                Progreso de cumplimiento mes de {branch.month}
                              </b>
                            </p>
                          </div>

                          <div className="col-0 md:col-2 pb-0 visiblemd">
                            <p className="metaF">
                              Meta <i className="pi pi-flag"></i>
                            </p>
                          </div>
                        </div>
                        {/* monto actual / monto objetivo * 100 */}
                        <ProgressBar
                          value={
                            branch.branchCompliance > 0
                              ? (
                                  (branch.branchCompliance /
                                    branch.salesGoals) *
                                  100
                                ).toFixed(2)
                              : 0
                          }
                        ></ProgressBar>

                        <div className="col-12 visiblesm">
                          <p className="metaF">
                            Meta <i className="pi pi-flag"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Compliance;
