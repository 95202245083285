import React from "react";
//PRIMEREACT
//COMPONENTES
//ESTILOS
import "./Campaign.css";
//FIREBASE

function History({ campana, name, perInit, perEnd, year, setSelectCampanas, selectCampanas, id }) {
  return (
    <>
      <div>
        <div className="campanasHis">
          <p
            className={`tcampanas mb-1 ${selectCampanas.id === id ? "activateC" : ""}`}
            onClick={() => {
              setSelectCampanas(campana);
            }}
          >
            {name}
          </p>
          <p className="subtcampanas mt-0">
            {perInit} / {perEnd} {year}
          </p>
        </div>

        <hr className="separador" />
      </div>
    </>
  );
}

export default History;
