import React from "react";
//PRIMEREACT
//COMPONENTES
//ESTILOS
import "./Insignias.css";
//MEDIA
import M5 from "../../assets/images/5_olimpos.png";
import M10 from "../../assets/images/10_olimpos.png";
import M15 from "../../assets/images/15_olimpos.png";
import moment from "moment";
//FIREBASE
import myformats from "../../services/myformats";

function InsigniasStatus({ dataInsignias }) {
  return (
    <>
      <div className="col-12 md:col-10 md:col-offset-1">
        <h3 className="text-center">
          Insignia del mes de {" "}
          {myformats.cFL(
            moment(dataInsignias.monthAndYear.toDate()).format("MMMM")
          )}
        </h3>

        <div className="grid">
          <div className="col-12 md:col-4 text-center pt-0">
            <img
              src={dataInsignias.imgUrl}
              alt="Insignias"
              className="imgInsignias"
            />

            <p className="nameIng">
              <b>Insignia {dataInsignias.name}</b>
            </p>
          </div>
          <div className="col-12 md:col-8">
            <div className="grid">
              <div className="col-10">
                <div className="grid">
                  <div className="col-12 p-0">
                    <p className="tMisiones">{dataInsignias.misiones[0]}</p>
                  </div>
                  <div className="col-12">
                    <p className="textmisiones">10 Olimpos</p>
                  </div>
                </div>
              </div>
              <div className="col-2 pl-1 pr-1">
                <img src={M10} alt="Olimpo" className="imgMe" />
              </div>
            </div>

            <div className="grid">
              <div className="col-10">
                <div className="grid">
                  <div className="col-12 p-0">
                    <p className="tMisiones">{dataInsignias.misiones[1]}</p>
                  </div>
                  <div className="col-12">
                    <p className="textmisiones">5 Olimpos</p>
                  </div>
                </div>
              </div>
              <div className="col-2 pl-1 pr-1">
                {/* <img src={M10} alt="Olimpo" className="imgMe" /> */}
                <img src={M5} alt="Olimpo" className="imgMe" />
              </div>
            </div>

            <div className="grid">
              <div className="col-10">
                <div className="grid">
                  <div className="col-12 p-0">
                    <p className="tMisiones">{dataInsignias.misiones[2]}</p>
                  </div>
                  <div className="col-12">
                    <p className="textmisiones">15 Olimpos</p>
                  </div>
                </div>
              </div>
              <div className="col-2 pl-1 pr-1">
                <img src={M15} alt="Olimpo" className="imgMe" />
                {/* <img src={M15} alt="Olimpo" className="imgMe" /> */}
              </div>
            </div>

            <div className="grid">
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 p-0">
                    <p className="tTotal borderIrr">
                      Total <b>OLIMPOS</b> acumulables: 30
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InsigniasStatus;
