import React, { useState } from "react";
//COMPONENTES
import Messages from "../Others/Messages";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//MEDIA
import ImgComentario from "../../assets/images/imgU.png";
//PRIMEREACT
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
//SERVICES
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";

function UploadsNoti({ selectNoti, dataUser, configurations }) {
  const [submitted, setSubmitted] = useState(false);
  const [imgPremio, setImgPremio] = useState(null);
  const [imgPremioSrc, setImgPremioSrc] = useState(ImgComentario);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      noticia: "",
    },
    validationSchema: Yup.object({
      noticia: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const storeImg = await fglobales.addNoti(
          "notiBrilla",
          selectNoti,
          formData,
          dataUser,
          imgPremio,
          configurations.eCommunications
        );

        if (storeImg) {
          setIcon(storeImg.icon);
          setColorIcon(storeImg.colorIcon);
          setHeaderMessage(storeImg.headerMessage);
          setMessage(storeImg.message);
          setShowMessage(true);
          setSubmitted(false);
          setImgPremioSrc(ImgComentario);
          setImgPremio(null);
          formik.resetForm();
        }
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
  };

  const handleCapture = (event) => {
    if (!event) {
      return;
    }
    const files = event.target.files;
    if (!files) {
      return;
    }

    const imgPremio = files[0];
    if (!imgPremio) {
      return;
    }

    const fileTypes = ["image/jpeg", "image/png"];
    if (!fileTypes.includes(imgPremio.type)) {
      toast.error("Subir fotos .jpeg o .png");
      return;
    }

    if (imgPremio.size > 20 * 1024 * 1024) {
      toast.error("Imagen demasiado grande:", imgPremio.size);
      return;
    }

    setImgPremio(imgPremio);
    setImgPremioSrc(URL.createObjectURL(imgPremio));
  };

  return (
    <>
      <div className="col-12">
        {submitted ? (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration="1s"
          />
        ) : (
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="grid">
              <div className="col-12 md:col-3 sinPT">
                <div className="fotoNoti">
                  <input
                    type="file"
                    name="imgPremio"
                    id="imgPremio"
                    className="SubirFoto"
                    accept="image/*"
                    capture="camera"
                    onChange={handleCapture}
                  />
                  <label
                    htmlFor="imgPremio"
                    onChange={handleCapture}
                    className="imagenSubir"
                  >
                    {imgPremioSrc ? (
                      <img src={imgPremioSrc} alt="Imagen Comentario" />
                    ) : (
                      <img src={ImgComentario} alt="Imagen Comentario" />
                    )}

                    <p className="adjunto m-0">
                      {!imgPremio ? (
                        <>
                          Adjuntar foto <i className="pi pi-paperclip"></i>
                        </>
                      ) : (
                        <>
                          {imgPremio.name} <i className="pi pi-paperclip"></i>
                        </>
                      )}
                    </p>
                  </label>
                </div>
              </div>

              <div className="col-12 md:col-9 sinPT">
                <InputTextarea
                  placeholder="¡En Brilla somos una comunidad! Si tienes una noticia para compartirles a todos, escríbenos, carga tu nota y adjunta tu foto."
                  value={formik.values.noticia}
                  id="noticia"
                  name="noticia"
                  onChange={formik.handleChange}
                  rows={5}
                  cols={30}
                  className={classNames(
                    {
                      "p-invalid": isFormFieldValid("noticia"),
                    },
                    "notiText"
                  )}
                  disabled={submitted}
                />
                {getFormErrorMessage("noticia")}
              </div>
              <div className="col-12 md:col-4 md:col-offset-4">
                <Button
                  className="p-button-brilla"
                  disabled={submitted}
                  type="submit"
                >
                  Enviar noticia
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default UploadsNoti;
