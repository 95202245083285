import React, { useState, useEffect } from "react";
//COMPONENTES
import Messages from "../Others/Messages";
import LoaderSimple from "../LoaderSimple";
import ActionButtons from "./ActionButtons";
// PRIMEREACT
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
//MEDIA
//SERVICIOS
import regex from "../../services/regex";
import fglobales from "../../services/fglobales";
//FIREBASE
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

export default function ModalAdviser({
  showMessageAdviser,
  actionHidden,
  dataUserPre,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [adviserData, setAdviserData] = useState([]);
  //CONTROL DE MENSAJES -->
  const [showMessageVali, setShowMessageVali] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  const [editData, setEditData] = useState([]);
  //CONTROL DE MENSAJES <--

  useEffect(() => {
    const q = query(
      collection(db, "adviser"),
      where("codeAccessUser", "==", dataUserPre.codeAccess)
    );

    const unlistenI = onSnapshot(q, (docs) => {
      let bannersPage = docs.docs.map((doc, index) => {
        return { ...doc.data(), id: doc.id, recordNumber: index + 1 };
      });

      setAdviserData(bannersPage);
    });
    return () => {
      unlistenI();
    };
  }, [dataUserPre.codeAccess]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      names: editData.names ? editData.names : "",
      surnames: editData.surnames ? editData.surnames : "",
      dni: editData.dni ? editData.dni : "",
      mobilePhone: editData.mobilePhone ? editData.mobilePhone : "",
      email: editData.email ? editData.email : "",
    },
    validationSchema: Yup.object({
      names: Yup.string().required("Este campo es requerido"),
      surnames: Yup.string().required("Este campo es requerido"),
      dni: Yup.string()
        .matches(regex.dni, "El campo debe tener solo dígitos")
        .required("Este campo es requerido"),
      mobilePhone: Yup.string()
        .matches(regex.mobilePhone, "El campo debe tener 10 dígitos")
        .required("Este campo es requerido"),
      email: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        if (editData.length === 0) {
          const docUser = await fglobales.getExistNoId(
            "adviser",
            "dni",
            formData.dni
          );

          if (docUser) {
            setIcon("pi-exclamation-triangle");
            setColorIcon("secondary-color");
            setHeaderMessage("¡Atención! tu consulta ha fallado");
            setMessage(
              <>
                Tu Cédula ya se encuentra registrada en nuestra base de datos
                favor contactar a soporte{" "}
                <a href="mailto:Fidelizacion.brilla@Surtigas.co">
                  Fidelizacion.brilla@Surtigas.co
                </a>
              </>
            );
            setShowMessageVali(true);
            setSubmitted(false);
            return;
          }

          await fglobales.addAdviser("adviser", formData, dataUserPre);
          setSubmitted(false);
          // actionHidden();
          formik.resetForm();
        } else {
          const docUserUp = await fglobales.updateDoc(
            "adviser",
            editData.id,
            formData
          );

          if (docUserUp) {
            setIcon("pi-check-circle");
            setColorIcon("tertiary-color");
            setHeaderMessage("¡Felicidades! tu registro se actualizo");
            setMessage(
              <>¡Felicidades! tu registro se actualizo correctamente</>
            );
            setShowMessageVali(true);
            setSubmitted(false);
            formik.resetForm();
            setEditData([]);
            return;
          }
        }
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessageVali(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessageVali(false);
    setSubmitted(false);
  };

  const actionBody = (rowData) => {
    return (
      <ActionButtons
        collectionText={"adviser"}
        rowData={rowData}
        setEditData={setEditData}
      />
    );
  };

  return (
    <>
      <Dialog
        visible={showMessageAdviser}
        onHide={() => actionHidden()}
        position="center"
        // footer={dialogFooter}
        showHeader
        style={{ width: "80%", borderRadius: "30px" }}
        breakpoints={{ "960px": "95%" }}
        header={<h3 className="m-0 text-center">Asesores registrados</h3>}
      >
        <div className="grid grid-nogutter">
          <div className="col-12 px-3 md:px-7 md:pb-4 pb-2">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="grid">
                <div className="col-12 md:col-4 sinPT">
                  <p className="pForm">Cédula: </p>

                  <InputText
                    id="dni"
                    name="dni"
                    value={formik.values.dni}
                    onChange={formik.handleChange}
                    disabled={submitted || editData.dni ? true : false}
                    type="tel"
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("dni"),
                      },
                      "textForm"
                    )}
                  />
                  {getFormErrorMessage("dni")}
                </div>

                <div className="col-12 md:col-4 sinPT">
                  <p className="pForm">Nombres: *</p>
                  <InputText
                    id="names"
                    name="names"
                    value={formik.values.names}
                    onChange={formik.handleChange}
                    disabled={submitted}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("names"),
                      },
                      "textForm"
                    )}
                  />
                  {getFormErrorMessage("names")}
                </div>

                <div className="col-12 md:col-4 sinPT">
                  <p className="pForm">Apellidos: *</p>
                  <InputText
                    id="surnames"
                    name="surnames"
                    value={formik.values.surnames}
                    onChange={formik.handleChange}
                    disabled={submitted}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("surnames"),
                      },
                      "textForm"
                    )}
                  />
                  {getFormErrorMessage("surnames")}
                </div>

                <div className="col-12 md:col-4 sinPT">
                  <p className="pForm">Celular: *</p>
                  <InputMask
                    id="mobilePhone"
                    name="mobilePhone"
                    mask="(999) 999-9999"
                    placeholder="(999) 999-9999"
                    value={formik.values.mobilePhone}
                    onChange={(e) =>
                      formik.setFieldValue("mobilePhone", e.value)
                    }
                    disabled={submitted}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("mobilePhone"),
                      },
                      "textForm"
                    )}
                    type="tel"
                  ></InputMask>
                </div>

                <div className="col-12 md:col-4 sinPT">
                  <p className="pForm">Correo electrónico: *</p>
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    disabled={submitted}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("email"),
                      },
                      "textForm"
                    )}
                  />

                  {getFormErrorMessage("email")}
                </div>

                <div className="col-12 md:col-4">
                  <br />
                  <Button
                    className="p-button-brilla"
                    type="submit"
                    disabled={submitted}
                  >
                    {editData.length === 0 ? "Agregar" : "Actualizar"}
                  </Button>
                </div>
              </div>
            </form>

            <div className="grid mt-4">
              <div className="col-12">
                <h3 className="m-0 text-center">Lista de Asesores</h3>
              </div>

              <div className="col-12">
                {adviserData ? (
                  <div>
                    <DataTable
                      value={adviserData}
                      responsiveLayout="scroll"
                      className="tableTitle"
                    >
                      <Column field="recordNumber" header="#"></Column>
                      <Column field="names" header="Nombres"></Column>
                      <Column field="surnames" header="Apellidos"></Column>
                      <Column field="dni" header="Cédula"></Column>
                      <Column field="mobilePhone" header="Teléfono"></Column>
                      <Column field="email" header="Correo"></Column>
                      <Column
                        field="id"
                        header="Acciones"
                        body={actionBody}
                      ></Column>
                    </DataTable>
                  </div>
                ) : (
                  <LoaderSimple />
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Messages
        showMessage={showMessageVali}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
      <ConfirmDialog />
    </>
  );
}
