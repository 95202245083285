import React, { useState, useEffect } from "react";
//PRIMEREACT
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import Video from "./Video";
import ModalVideo from "./ModalVideo";
import Albumes from "../Albumes";
//ESTILOS
import "./Portal.css";
//MEDIA
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//FIREBASE
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Portal() {
  const { dataUser, configurations } = useUsuario();
  const [dataVideo, setDataVideo] = useState([]);
  const [albumesData, setAlbumesData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showVideo, setShowVideo] = useState("");

  useEffect(() => {
    const q = query(
      collection(db, "portal"),
      orderBy("date", "desc")
      // where("districtId", "==", dataUser.districtId)
    );
    const unlistenI = onSnapshot(q, (docs) => {
      let dataVi = [];

      docs.forEach((doc) => {
        if (
          doc.data().districtId === dataUser.districtId ||
          doc.data().districtId === configurations.codeDistrictGeneral
        ) {
          if (doc.data().status === true) {
            dataVi.push({ ...doc.data(), id: doc.id });
          }
        }
      });

      setDataVideo(dataVi);
    });
    return () => {
      unlistenI();
    };
  }, [dataUser.districtId, configurations.codeDistrictGeneral]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataAlbumes = await fglobales.getDatosFilDis(
          "albumes",
          "status",
          true,
          dataUser,
          configurations
        );

        setAlbumesData(dataAlbumes);
      } catch (error) {
        // Manejar errores de manera adecuada
        console.error("Error al obtener datos de usuarios ganadores:", error);
      }
    };

    fetchData();
  }, [dataUser, configurations]);

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  const openModal = (videoUrl, videoName) => {
    setShowVideo({
      url: videoUrl,
      name: videoName,
    });
    setShowMessage(true);
  };

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <h1 className="mt-0">
            Conoce más sobre el
            <br />
            portal Brilla
          </h1>

          <br />
          <Albumes albumesData={albumesData} />
          <br />
          <div className="grid">
            <div className="col-12 contentForm">

              {dataVideo ? (
                dataVideo.map((d, i) => {
                  return (
                    <div key={i}>
                      <Video dataTutorial={d} openModal={openModal} />
                    </div>
                  );
                })
              ) : (
                <LoaderSimple />
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalVideo
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        showVideo={showVideo}
      />
    </>
  );
}

export default Portal;
