import React, { useState, useEffect } from "react";
//PRIMEREACT
//COMPONENTES
import ModalSurveys from "./ModalSurveys";
//ESTILOS
import "./Surveys.css";
//MEDIA
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//FIREBASE
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Surveys() {
  const { dataUser, configurations } = useUsuario();
  const [surveysData, setSurveysData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  // const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (dataUser && configurations) {
      const getDataAsync = async () => {
        try {
          const docSu = await fglobales.getSur(
            "surveys",
            configurations.surveysId
          );

          setSurveysData(docSu);

          if (
            docSu.status &&
            !dataUser.surveysId.includes(configurations.surveysId)
          ) {
            setShowMessage(true);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getDataAsync();
    }
  }, [configurations, dataUser]);

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  const updateSurveysId = async () => {
    const q = doc(db, "usersClients", dataUser.userId);
    try {
      await updateDoc(q, {
        surveysId: arrayUnion(configurations.surveysId),
      });
      // setSubmitted(true);
    } catch (e) {
      console.log("Transacción fallida: ", e);
    }
  };

  return (
    <>
      <ModalSurveys
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        surveysData={surveysData}
        updateSurveysId={updateSurveysId}
      />
    </>
  );
}

export default Surveys;
