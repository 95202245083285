import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
//MEDIA

export default function ModalLogouts({ showMessage, actionHidden, logouts }) {
  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      //   footer={dialogFooter}
      showHeader={false}
      style={{ width: "40%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      //   header={"¿Estás seguro que deseas cerrar sesión?"}
    >
      <>
        <div className="col-12 p-4">
          <h3 className="text-center mt-3">
            ¿Estás seguro que deseas cerrar sesión?
          </h3>
          <div className="grid">
            <br />
            <div className="col-6 overflow-hidden">
              <Button
                label="SÍ"
                className="p-button-brilla"
                autoFocus
                onClick={() => {
                  logouts();
                }}
              />
            </div>
            <div className="col-6 overflow-hidden">
              <Button
                label="NO"
                className="p-button-brilla"
                autoFocus
                onClick={() => {
                  actionHidden();
                }}
              />
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}
