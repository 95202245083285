import React, { useState } from "react";
//COMPONENTES
import Messages from "../Others/Messages";
//RUTAS
import { Link } from "react-router-dom";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
//SERVICES
import { toast } from "react-toastify";
import authentication from "../../services/authentication";
//CONTEXT
// import { useUsuario } from "../../context/usuario-context";

function Login({ changeControl }) {
  // const { configurations } = useUsuario();
  const [submitted, setSubmitted] = useState(false);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [icon, setIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
      password: Yup.string().required("La contraseña es obligatoria"),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);
        authentication
          .signIn(formData.email, formData.password)
          .then((result) => {
            toast.success(result);
            setSubmitted(false);
            setTimeout(() => {
              window.location.reload();
            }, 50);
            formik.resetForm();
          })
          .catch((error) => {
            setHeaderMessage("¡Atención!");
            setColorIcon("secondary-color");
            setIcon("pi-exclamation-triangle");
            setMessage(error);
            setShowMessage(true);
            // toast.error(error);
            console.log(error);
            setSubmitted(false);
          });
        setSubmitted(false);
      } catch (error) {
        toast.error(error);
        // console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="col-12">
        <h2>Inicia sesión</h2>
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="grid">
            <div className="col-12 sinPT">
              <p className="pForm">Correo electrónico:</p>
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={submitted}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("email"),
                  },
                  "textForm"
                )}
              />

              {getFormErrorMessage("email")}
            </div>
            <div className="col-12 sinPT">
              <p className="pForm">Contraseña:</p>
              <Password
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                disabled={submitted}
                feedback={false}
                className={classNames(
                  {
                    "p-invalid": isFormFieldValid("password"),
                  },
                  "textForm"
                )}
                autoComplete="off"
                toggleMask
              />
              {getFormErrorMessage("password")}
            </div>

            <div className="col-12 md:col-4 md:col-offset-4">
              <Button
                className="p-button-brilla"
                type="submit"
                disabled={submitted}
              >
                Ingresar
              </Button>
            </div>

            <div className="col-6">
              <p
                className="recuperarPass"
                onClick={() => {
                  changeControl();
                }}
              >
                Recuperar contraseña
              </p>
            </div>

            <div className="col-6">
              <p className="recuperarPass2">
                <Link className="texto" to="/registrate">
                  Registrarse
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        icon={icon}
        color={colorIcon}
      />
    </>
  );
}

export default Login;
