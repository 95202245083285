import React from "react";
//PRIMEREACT
import { Button } from "primereact/button";
//COMPONENTES
import ReactPlayer from "react-player";

function Pdf({ selectNoti, downloadPdf, setDownloadPdf }) {
  const descargarPDF = () => {
    setDownloadPdf(true);
    fetch(selectNoti.urlPDF)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobURL;
        a.download = `${selectNoti.desc}.pdf`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        setTimeout(() => {
          setDownloadPdf(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  return (
    <>
      <>
        <div className='grid grid-nogutter'>
          <div className='col-12'>
            <h3 className='text-center mt-1 mb-2'>{selectNoti.desc}</h3>
            <br />
          </div>

          <div className='col-12 md:col-10 md:col-offset-1'>
            {selectNoti.type.code === 1 ? (
              <iframe
                src={selectNoti.urlPDF}
                width='100%'
                className='iframeNoti'
                title='Notibrilla'
              />
            ) : (
              <div
                div
                className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url={selectNoti.urlVideo}
                  width='100%'
                  height='100%'
                  controls={true}
                  playsinline
                  // onEnded={() => endVideo()}
                  onError={(error) => console.log(error)}
                />
              </div>
            )}
          </div>

          <div className='col-12 md:col-10 md:col-offset-1 pt-5'>
            {selectNoti.type === 1 ? (
              <Button
                className='p-button-brilla'
                type='submit'
                disabled={downloadPdf}
                onClick={() => {
                  descargarPDF();
                }}>
                Descargar pdf
              </Button>
            ) : null}
          </div>
        </div>
      </>
    </>
  );
}

export default Pdf;
