import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./Loader.css";

function Loader() {
  return (
    <>
      <div className="containerLoader">
        <PuffLoader color="#ffb300" size={78} />
      </div>
    </>
  );
}

export default Loader;
