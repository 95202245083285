import React from "react";
//PRIMEREACT
//COMPONENTES
//ESTILOS
import "./NotiBrillaHistorical.css";
//FIREBASE

function History({ newsData, setSelectNoti, selectNoti }) {
  
  return (
    <>
      <div>
        <div className="campanasHis">
          <p
            className={`tNotibrilla mb-1 ${newsData.id === selectNoti.id ? "activateH" : ""}`}
            onClick={() => {
              setSelectNoti(newsData);
            }}
          >
            {newsData.desc}
          </p>
        </div>

        <hr className="separador" />
      </div>
    </>
  );
}

export default History;
