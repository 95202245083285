const myfunctions = {};

myfunctions.formatData = (data) => {
  const array = Object.entries(data);
  const filteredStrings = array
    .filter(([___, value]) => typeof value === "string")
    .map((d) => [d[0], d[1].toLowerCase()]);
  const justStrings = Object.fromEntries(filteredStrings);
  const filteredObjects = array.filter(
    ([___, value]) => typeof value === "object"
  );
  const justObjects = Object.fromEntries(filteredObjects);
  const filteredNumber = array.filter(
    ([___, value]) => typeof value === "number"
  );
  const justNumber = Object.fromEntries(filteredNumber);

  return Object.assign(justStrings, justObjects, justNumber);
};

myfunctions.cFL = (str) => {
  //Formatea en mayúscula la primera letra
  return str.charAt(0).toUpperCase() + str.slice(1);
};
myfunctions.cFTL = (str) => {
  //Formatea en mayúscula la primera letra y minuscula el resto
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

myfunctions.formatCurrency = (value) => new Intl.NumberFormat("de-DE", { style: "currency", currency: "COP" }).format( value );

myfunctions.formatDE = (value) => new Intl.NumberFormat("de-DE").format(value);

myfunctions.isFormFieldValid = (name, formik) =>
  !!(formik?.touched[name] && formik?.errors[name]);

myfunctions.getFormErrorMessage = (name, formik) => {
  return (
    myfunctions.isFormFieldValid(name) && (
      <small className="p-error">{formik?.errors[name]}</small>
    )
  );
};

export default myfunctions;
