import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
// VIDEO
import ReactPlayer from "react-player";

export default function ModalVideo(props) {
  const { showMessage, actionHidden, showVideo } = props;

  // const dialogFooter = (
  //   <div className="flex flex-column align-items-center justify-content-center">
  //     <Button
  //       label="Cerrar"
  //       className="p-button-brilla"
  //       autoFocus
  //       onClick={() => actionHidden()}
  //     />
  //   </div>
  // );

  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      // footer={dialogFooter}
      showHeader
      style={{ width: "60%", borderRadius: "30px" }}
      breakpoints={{ "960px": "95%" }}
      header={showVideo.name}
    >
      <div className="p-4 pt-0 md:p-6 md:pt-0">
        <div div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={showVideo.url}
            width="100%"
            height="100%"
            controls={true}
            playing={showMessage}
            playsinline
            // onEnded={() => endVideo()}
            onError={(error) => console.log(error)}
          />
        </div>
      </div>
    </Dialog>
  );
}
