import React from "react";
//PRIMEREACT
import { TabView, TabPanel } from "primereact/tabview";
//COMPONENTES
//ESTILOS
import "./Insignias.css";
//MEDIA
import Bloqueada from "../../assets/images/insigniabloqueada.png";
//FIREBASE

function InsigniasStructure() {
  let semestres = [1, 2, 3, 4]; // Numero de semestres

  return (
    <>
      <div className="contenedorIng">
        <div className="tabview-demo">
          <TabView>
            {semestres.map((semestre) => {
              return (
                <TabPanel header={`Ciclo ${semestre}`} key={semestre}>
                  <div className="grid">
                    {/* Mensual */}
                    <div className="col-12 md:col-6">
                      <p className="tAcumulada">Mensuales</p>
                      <div className="grid">
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-4 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                      </div>
                    </div>

                    {/* Trimestral */}
                    <div className="col-12 md:col-2">
                      <p className="tAcumulada">Trimestrales</p>
                      <div className="grid">
                        <div className="col-6 md:col-12 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                        <div className="col-6 md:col-12 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 md:col-4">
                      <p className="tAcumulada">Semestrales</p>
                      <div className="grid">
                        <div className="col-12 userIng">
                          <img src={Bloqueada} alt="Insignias" />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </TabView>
        </div>
      </div>
    </>
  );
}

export default InsigniasStructure;
