import React, { useState } from "react";
//COMPONENTES
import Login from "./Login";
import RecuperarPass from "./RecuperarPass";
//ESTILOS
import "./Login.css";
//MEDIA

function Information() {
  const [recover, setRecover] = useState(false);

  const changeControl = () => {
    setRecover(!recover);
  };

  return (
    <>
      <div className="grid grid-nogutter container-principal">
        <div className="col-12 md:col-10 md:col-offset-1">
          <div className="grid grid-nogutter">
            <div className="col-12 md:col-10 md:col-offset-1">
              <h1>
                ¿Ya haces parte de Titanes Brilla?
                <br />
                Ingresa ahora
              </h1>
              <p className="text-3xl md:text-5xl text-center">
                <b>¡Ya conoces cómo ganar cada insignia!</b>
              </p>
              <p className="text-center">
                Al iniciar sesión, comenzarán a cumular OLIMPOS que los llevarán
                a alcanzar La Insignia de Dios, La Espada de Zeus y El Poder
                Titán.
                <br /> ¿Qué esperan para ingresar?
              </p>
            </div>

            <div className="col-12 md:col-10 md:col-offset-1 contentForm">
              {!recover ? (
                <Login changeControl={changeControl} />
              ) : (
                <RecuperarPass
                  recover={recover}
                  changeControl={changeControl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Information;
